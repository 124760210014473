import * as AlertDialog from '@radix-ui/react-alert-dialog';

function MainDialog({
  children,
  open,
  onOpenChange,
}) {
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </AlertDialog.Root>
  );
}

function MainDialogContent({ title, description, children, ...props }) {
  return (
    <AlertDialog.Portal>
      <AlertDialog.Overlay className="AlertDialogOverlay" />
      <AlertDialog.Content className="AlertDialogContent">
        <AlertDialog.Title className="AlertDialogTitle">
          {title}
        </AlertDialog.Title>
        <AlertDialog.Description className="AlertDialogDescription">
          {description}
        </AlertDialog.Description>
        <div className="AlertDialogChildrenContent">
          {children}
        </div>
        <AlertDialog.Cancel asChild />
        <AlertDialog.Action asChild />
      </AlertDialog.Content>
    </AlertDialog.Portal>
  );
}

MainDialog.Trigger = AlertDialog.Trigger;
MainDialog.Content = MainDialogContent;

export default MainDialog;
