import React from "react";
import styled from 'styled-components';

export function Footer({}) {
  return (
    <StyledFooter className="voic-footer">
      <div className="voic-footer__left">
        <p>Università degli Studi di Torino - Via Verdi, 8 - 10124 Torino<br/>
          P.I. 02099550010 - C.F. 80088230018 - IBAN: IT07N0306909217100000046985</p>
      </div>
      <div className="voic-footer__right">
        <p>© Laboratorio Polin - Dipartimento di Matematica "G. Peano"</p>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
	position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #21315B;
  color: #ffffff;
  height: 3.5rem;
  padding: 0 2rem;
  p {
    font-family: 'Titillium Web',sans-serif;
    margin: 0;
    font-size: 12px;
  }
`;