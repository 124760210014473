// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("cmunss.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("cmunsx.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("cmunsi.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("cmunso.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'Computer Modern Sans';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Sans';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Sans';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
	font-weight: normal;
	font-style: italic;
}


@font-face {
	font-family: 'Computer Modern Sans';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
	font-weight: bold;
	font-style: oblique;
}
`, "",{"version":3,"sources":["webpack://./src/fonts/CMU/Sans/cmun-sans.css"],"names":[],"mappings":"AAAA;CACC,mCAAmC;CACnC,2DAAsC;CACtC,mBAAmB;CACnB,kBAAkB;AACnB;;;AAGA;CACC,mCAAmC;CACnC,2DAAsC;CACtC,iBAAiB;CACjB,kBAAkB;AACnB;;;AAGA;CACC,mCAAmC;CACnC,2DAAsC;CACtC,mBAAmB;CACnB,kBAAkB;AACnB;;;AAGA;CACC,mCAAmC;CACnC,2DAAsC;CACtC,iBAAiB;CACjB,mBAAmB;AACpB","sourcesContent":["@font-face {\n\tfont-family: 'Computer Modern Sans';\n\tsrc: url('cmunss.woff') format('woff');\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n\n\n@font-face {\n\tfont-family: 'Computer Modern Sans';\n\tsrc: url('cmunsx.woff') format('woff');\n\tfont-weight: bold;\n\tfont-style: normal;\n}\n\n\n@font-face {\n\tfont-family: 'Computer Modern Sans';\n\tsrc: url('cmunsi.woff') format('woff');\n\tfont-weight: normal;\n\tfont-style: italic;\n}\n\n\n@font-face {\n\tfont-family: 'Computer Modern Sans';\n\tsrc: url('cmunso.woff') format('woff');\n\tfont-weight: bold;\n\tfont-style: oblique;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
