import { useCallback, useState, useEffect, useRef, createRef } from 'react';
import styled from 'styled-components';
import { FormuleType } from '../types/job.types';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import toast, { Toaster } from 'react-hot-toast';
import { Copy, CheckCircle2, AlertCircle, XCircle, Eye, Code2 } from 'lucide-react';

export function FormulaPanel({
	formulas,
	tmpFormula,
	onEditFormula,
	formulaSuggestions,
	selectedFormula
}: {
	formulas: FormuleType[],
	tmpFormula: Record<number, string>
	onEditFormula: any
	formulaSuggestions: any
	selectedFormula: [number, number] | null
}) {
	const [activeVisualization, setActiveVisualization] = useState<Record<number, string>>(
		formulas.reduce((obj, item) => Object.assign(obj, { [item.id]: 'preview' }), {})
	);
	const [copyType, setCopyType] = useState<number>(0);
	const [selectedFormulaId, setSelectedFormulaId] = useState<number | null>(null);

	const onChangeView = useCallback(
		(view: string, index: number) => {
			switch (view) {
				case 'preview':
					if (activeVisualization[index] === 'both') {
						setActiveVisualization(
							Object.assign({}, activeVisualization, {
								[index]: 'preview'
							})
						);
					}
					if (activeVisualization[index] === 'preview') {
						return;
					}
					if (activeVisualization[index] === 'latex') {
						setActiveVisualization(
							Object.assign({}, activeVisualization, {
								[index]: 'both'
							})
						);
					}
					break;
				case 'latex':
					if (activeVisualization[index] === 'both') {
						setActiveVisualization({
							...activeVisualization,
							[index]: 'latex'
						});
					}
					if (activeVisualization[index] === 'preview') {
						setActiveVisualization({ ...activeVisualization, [index]: 'both' });
					}
					if (activeVisualization[index] === 'latex') {
						return;
					}
					break;
			}
		},
		[activeVisualization]
	);

	const copyFormula = useCallback(
		id => {
			let textarea = document.getElementById(`formulaRef_${id}`) as HTMLInputElement;
			if (textarea !== null) {
				textarea.select();
				if (copyType === 0) {
					navigator.clipboard.writeText(`\\[ ${textarea.value} \\]`);
				}
				if (copyType === 1) {
					navigator.clipboard.writeText(`\\( ${textarea.value} \\)`);
				}
				if (copyType === 2) {
					navigator.clipboard.writeText(`\\begin{equation}\n${textarea.value}\n\\end{equation}`);
				}
			}
		},
		[copyType]
	);

	const INITIAL_OFFSET = 0;
	const LIMIT = 10;
	const [offset, setOffset] = useState<number>(INITIAL_OFFSET)
	const [items, setItems] = useState<FormuleType[]>(formulas.slice(INITIAL_OFFSET, LIMIT))

	function loadMoreFormulas() {
		const nextOffset = offset + 1;
		setOffset(nextOffset);
		const nextStartingPoint = nextOffset * LIMIT;
		const nextFormulas = formulas.slice(
			nextStartingPoint,
			nextStartingPoint + LIMIT
		);
		setItems(prevItems => [...prevItems, ...nextFormulas]);
	}

	// auto scroll to selected formula
	useEffect(() => {
		// console.log('selectedFormula:', selectedFormula);
		// console.log('formulaSuggestions:', formulaSuggestions);
		const blockIndex = selectedFormula ? selectedFormula[0] : null;
		const formulaIndex = selectedFormula ? selectedFormula[1] : null;
		const selectedBlock = blockIndex !== null ? formulaSuggestions[blockIndex] : null;
		const selectedFormulaObject = selectedBlock && selectedBlock[formulaIndex ? formulaIndex : 0];
		
		if (selectedFormulaObject && 'best_formula_id' in selectedFormulaObject && selectedFormulaObject.best_formula_id !== null) {
			// console.log('selectedFormulaObject:', selectedFormulaObject.best_formula_id);
			const element = document.querySelector(`[data-id="${selectedFormulaObject.best_formula_id}"]`);
			if (element) {
				const curr = ps.current
				if (curr) {
					curr.scrollTop = (element as HTMLElement).offsetTop - 11;
				}
				setSelectedFormulaId(selectedFormulaObject.best_formula_id);

				element.classList.remove('selected-animation');
				element.classList.add('selected-animation');
        element.addEventListener('animationend', () => {
					element.classList.remove('selected-animation');
				}, { once: true });
			} else {
				loadMoreFormulas();
			}
		} else {
			if (selectedFormula) {
        toast.error("Nessuna formula corrispondente");
    	}
		}
	}, [selectedFormula, formulaSuggestions, items]);

  const ps = useRef<any>()

	return (
		<PerfectScrollbar containerRef={el => (ps.current = el)}>
			<StyledFormulaContainer>
				<div className="select-dropdown">
					<label htmlFor="copy_type">Scegli il tag con cui vuoi copiare le formule:</label>

					<select
						name="copy_type"
						id="copy_type"
						style={{ width: '100%' }}
						onChange={e => {
							setCopyType(Number(e.target.value));
						}}
					>
						<option defaultValue={0} value={0}>{`\\[...\\]`}</option>
						<option value={1}>{`\\(...\\)`}</option>
						<option value={2}>{`\\begin{equation}...\\end{equation}`}</option>
					</select>
				</div>
				{items.map(formula => {
					const confidence =
						formula.confidence >= 0.01 && formula.confidence < 0.05
							? 'medium'
							: formula.confidence >= 0.05
								? 'good'
								: 'bad';
					return (
						<StyledCardFormula
							key={formula.id}
							colorBorder={
								confidence === 'medium'
									? '0.2rem solid #ffbf46'
									: confidence === 'good'
										? '0.2rem solid #57a773'
										: '0.2rem solid #d1603d'
							}
							className={`formula-card ${formula.id === selectedFormulaId ? 'selected-border selected-animation' : ''}`}
							data-id={formula.id}
						>
							<StyledCardHeader>
								<div className="confidence">
									{confidence === 'good' && <CheckCircle2 color="#57a773" />}
									{confidence === 'medium' && <AlertCircle color="#ffbf46" />}
									{confidence === 'bad' && <XCircle color="#d1603d" />}
								</div>
								<div className="view">
									<button
										aria-label="Anteprima formula"
										className={`preview-button ${activeVisualization[formula.id] === 'preview' ||
												activeVisualization[formula.id] === 'both'
												? 'active'
												: ''
											}`}
										onClick={() => onChangeView('preview', formula.id)}
									>
										<Eye />
									</button>
									<button
										aria-label="Latex"
										className={`latex-button ${activeVisualization[formula.id] === 'latex' ||
												activeVisualization[formula.id] === 'both'
												? 'active'
												: ''
											}`}
										onClick={() => onChangeView('latex', formula.id)}
									>
										<Code2 />
									</button>
									<StyledButtonCopyInline
										aria-label="Copia formula"
										onClick={() => copyFormula(formula.id)}
										className="active"
									>
										<Copy />
									</StyledButtonCopyInline>
								</div>
							</StyledCardHeader>
							<StyledCardBody>
								<StyledCardBodyPreview
									isVisible={
										activeVisualization[formula.id] === 'preview' ||
										activeVisualization[formula.id] === 'both'
									}
								>
									<Latex>{`$${tmpFormula[formula.id]}$`}</Latex>
								</StyledCardBodyPreview>

								<StyledCardBodyEditing
									isCode={true}
									isVisible={
										activeVisualization[formula.id] === 'latex' ||
										activeVisualization[formula.id] === 'both'
									}
								>
									<StyledInputFormula
										isCode={true}
										className="formula_listener"
										id={`formulaRef_${formula.id}`}
										value={tmpFormula[formula.id]}
										onChange={e => onEditFormula(e.target.value, formula.id)}
										onCut={(e: any) => {
											e.preventDefault();
											return false;
										}}
										onCopy={(e: any) => {
											e.preventDefault();
											return false;
										}}
									></StyledInputFormula>
								</StyledCardBodyEditing>
							</StyledCardBody>
						</StyledCardFormula>
					);
				})}
				{items.length !== formulas.length && <button id="loadMoreButton" className="loadMore" onClick={loadMoreFormulas}>Carica altro...</button>}
			</StyledFormulaContainer>
			<Toaster
				position="bottom-right"
				reverseOrder={false}
				toastOptions={{
					duration: 5000,
				}}
			/>
		</PerfectScrollbar>
	);
}

const StyledButtonCopyInline = styled.button`
height: 3vh;
width: 3vh;
cursor: pointer;
position: relative;
margin-left: 2rem;
outline: none;
border: none;
background: inherit;
:hover {
	:before {
		content: 'Copy';
		font-size: 1.5vh;
		position: absolute;
		top: -1.2rem;
		right: -0.2rem;
		background: rgba(55, 97, 182, 0.1);
		border-radius: 0.3rem;
		padding: 0.2rem;
	}
}
svg {
	.cls-copy {
		fill: #1c325e;
	}
}
`;
const StyledFormulaContainer = styled.div`
display: flex;
flex-direction: column;
padding: 1.5rem;
.loadMore{
	margin:auto;
	width:50%;
	border: none;
	background: no-repeat;
	color: #1c325e;
	&:hover{
		color:#3761b6;
	}

}
.select-dropdown {
	margin: 3vh 0;
	font-size: 2vh;
	label {
		margin-right: 1vh;
	}
	select {
		font-size: inherit;
	}
}
`;
const StyledCardFormula = styled.div<{ colorBorder: string }>`
display: flex;
flex-direction: column;
border-radius: 1rem;
//background:rgba(55,97,182,0.1);
border: ${props => props.colorBorder};
box-shadow: 0 8px 6px -6px black;
margin-bottom: 1rem;
&.selected-border {
	outline: 0px solid rgba(55, 97, 182, 0.4);
	border-color: #3761b6 !important;
	.confidence svg {
		stroke: #3761b6 !important;
	}
}
&.selected-animation {
	animation: outline-pulse 1.5s ease-in-out 2;
}
@keyframes outline-pulse {
  0% { outline-width: 0px; }
  50% { outline-width: 5px; }
  100% { outline-width: 0px; }
}
`;
const StyledCardHeader = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
align-content: center;
background: rgba(55, 97, 182, 0.1);
padding: 0.5rem;
.confidence {
	svg {
		height: 2rem;
	}
}
.view {
    display: flex;
    button {
        outline: none;
        border: 0;
        background: transparent;
        &.preview-button {
            position: relative;
            :hover {
                :before {
                    content: 'Preview';
                    position: absolute;
                    right: 0rem;
                    top: -1.2rem;
                    background: rgba(55, 97, 182, 0.1);
                    border-radius: 0.3rem;
                    padding: 0.2rem;
                }
            }
        }
        &.latex-button {
            position: relative;
            :hover {
                :before {
                    content: 'Edit';
                    position: absolute;
                    /* bottom: -1rem; */
                    right: 0rem;
                    top: -1.2rem;
                    background: rgba(55, 97, 182, 0.1);
                    border-radius: 0.3rem;
                    padding: 0.2rem;
                }
            }
        }
        svg {
            height: 2vh;
            opacity: 0.2;
        }
        cursor: pointer;
        &.active {
            svg {
                opacity: 1;
            }
        }
    }
}
`;
const StyledCardBody = styled.div``;
const StyledCardBodyEditing = styled.div<{
	isCode: boolean;
	isVisible: boolean;
}>`
background: #11151c;
color: white;
position: relative;
display: ${props => (props.isVisible ? 'block' : 'none')};
border-radius: ${props => props.isCode && '0 0 0.8rem 0.8rem'};
`;
const StyledCardBodyPreview = styled.div<{ isVisible: boolean }>`
font-size: 2.5vh;
padding: 1.5rem 1rem;
display: ${props => (props.isVisible ? 'block' : 'none')};
overflow-x: overlay;
`;

const StyledInputFormula = styled.textarea<{ isCode?: boolean }>`
font-size: 2.1vh;
height: 6rem;
width: 97%;
font: inherit;
margin: 0.5em;
resize: none;
appearance: none;
border: none;
background: #11151c;
color: #fffffb;
outline: none;
border-radius: ${props => props.isCode === true && '0 0 0.8rem 0.8rem'};
`;
