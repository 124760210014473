import styled from 'styled-components';
import { useEffect, useState, useCallback } from 'react';
import { addJob, getAllJobs, getUploadedData, getJob, checkSpace } from '../services/useServicesList';
import { PreviousJobs } from '../components/PreviousJobs';
import { FilesList } from '../components/FilesList';
import { Footer } from '../components/Footer';
import MainDialog from '../components/MainDialog';
import { Button } from 'react-bootstrap';
import Loader from 'react-spinners/MoonLoader';

export function Jobs({
	setApplicationState,
	setReady,
	errorState
}: {
	setApplicationState(applicationState: 'NOT_SELECTED' | 'SELECTED' | 'ERROR'): void;
	setReady: (ready: any) => void;
	errorState: (error: any) => void;
}) {
	const [jobs, setJobs] = useState<Array<any>>();
	const [totalJobs, setTotalJobs] = useState<number>(0);
	const [filesUploaded, setFilesUploaded] = useState<Array<any>>();
	const [hasAvailableSpace, setHasAvailableSpace] = useState<boolean>(true);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [driveSpace, setDriveSpace] = useState<number>(0);
	const [localSpace, setLocalSpace] = useState<number>(0);

	let jobsTimeout: any = null;

	function updateAllJobsList() {
		getAllJobsCallback();
	}

	const getAllJobsCallback = useCallback(
		(startIndex?: number, endIndex?: number, activePage?: number) => {
			getAllJobs(startIndex, endIndex)
				.then((res) => {
					if (res) {
						if (activePage !== 0) {
							clearTimeout(jobsTimeout);
						}
						if (
							res.data.jobs.find((single: any) => {
								return single.status === "BUSY" || single.status === "LOADED";
							}) &&
							activePage !== 0
						) {
							// eslint-disable-next-line react-hooks/exhaustive-deps
							jobsTimeout = setTimeout(updateAllJobsList, 10000);
						} else {
						}
						setTotalJobs(res.data.total_jobs);
						return setJobs(res.data.jobs);
					}
				})
				.catch((e) => {
					errorState(e);
				});
		},
		[errorState]
	);

	useEffect(() => {
		getAllJobsCallback();
		return () => {
			clearTimeout(jobsTimeout);
		};
	}, [getAllJobsCallback, jobsTimeout]);

	useEffect(() => {
		checkSpace()
			.then(res => {
				if (res) {
					setDriveSpace(res.data.drive_space);
					setLocalSpace(res.data.local_space);
					if (!res.data.space_ok) {
						setHasAvailableSpace(false);
						setIsDialogOpen(true);
					}
				}
			})
			.catch(e => {
				errorState(e);
			});

		getUploadedData()
			.then(res => {
				if (res) {
					return setFilesUploaded(res.data);
				}
			})
			.catch(e => {
				errorState(e);
			});
	}, [errorState]);

	const addJobCallback = useCallback(
		(audioVideoPath: string, pdfPath: string[]) => {
			const video_path = audioVideoPath.indexOf('.mp4') !== -1 ? [audioVideoPath] : [];
			const audio_path = audioVideoPath.indexOf('.wav') !== -1 ? [audioVideoPath] : [];
			const pdf_paths = pdfPath;
			addJob(video_path, audio_path, pdf_paths)
				.then(() => {
					getAllJobsCallback();
					if (jobsTimeout) {
						clearTimeout(jobsTimeout);
					}
				})
				.catch((e) => {
					errorState(e);
				});
		},
		[errorState, getAllJobsCallback, jobsTimeout]
	);

	const getJobCallback = useCallback(
		(jobId: string) => {
			getJob(jobId)
				.then(res => {
					setReady(res.data);
					setApplicationState('SELECTED');
				})
				.catch((e) => {
					errorState(e);
				});
		},
		[errorState, setApplicationState, setReady]
	);

	return (
		<>
			<Main>
				{!filesUploaded  && (
				<div className='drive-loader'>
					<Loader color='#3761b6' size={50} />
					<p>Sincronizzazione dati Google Drive in corso...</p>
				</div>
				)}
				{filesUploaded && (
					<FilesList
						files={filesUploaded}
						addJob={addJobCallback}
						hasAvailableSpace={hasAvailableSpace}
					/>
				)}
				<div className='jobs'>
					{jobs && (
						<PreviousJobs
							jobs={jobs}
							getJob={getJobCallback}
							totalJobs={totalJobs}
							getAllJobsCallback={getAllJobsCallback}
						></PreviousJobs>
					)}
				</div>
			</Main>
			<MainDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<MainDialog.Content
						title="Spazio insufficiente"
						description={`Non è possibile caricare nuovi file, spazio insufficiente (Max. 5 GB), elimina alcuni file da Drive o alcune elaborazioni per liberare spazio e ricarica la pagina`}
					>
						<hr />
						<ul>
							<li>Spazio Drive occupato: {driveSpace} GB</li>
							<li>Spazio Locale occupato: {localSpace} GB</li>
						</ul>
						<div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
							<Button
								variant="flat"
								style={{ backgroundColor: '#3761B6', color: 'white' }}
								onClick={() => {
									setIsDialogOpen(false);
								}}
							>
								Chiudi
							</Button>
						</div>
					</MainDialog.Content>
			</MainDialog>
			<Footer />
		</>
	);
}

const Main = styled.main`
	display: flex;
	justify-content: space-between;
	padding: 0 5rem;
	.body {
		height: 90%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		.note {
			position: absolute;
			bottom: 02%;
			left: 2%;
		}
	}
	.drive-loader {
		display: flex;
		flex: 2;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 5rem;
		height: 83vh;
		p {
			color: #000;
			margin-top: 2rem;
		}
	}
	.jobs {
		width: 35%;
		height: 83vh;
		@media screen and (max-width: 1370px) {
			width: 40%;
		}
	}
`;
