import { useCallback, useEffect, useState } from 'react';
import { AlertCircle } from 'lucide-react';
import { minLetters, maxLetters, maths, arrows, table, theorem } from '../helpers/letters';
import styled from 'styled-components';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import Latex from 'react-latex-next';
import { Button } from 'react-bootstrap';

export function EditPanel({
	opar,
	selectedTab,
	updateMemoContent,
	updateMemoTextRef
}: {
	opar: Record<
		number,
		{
			text: string;
			start_time: string;
		}
	>;
	updateMemoContent: (
		memoText: Record<
			number,
			{
				text: string;
				start_time: string;
			}
		>
	) => void;
	selectedTab: 'Preview' | 'Formula' | 'Edit' | null | undefined;
	updateMemoTextRef: (newMemo: any) => void;
}) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [inputValue, setInputValue] = useState('');
	const [newValue, setNewValue] = useState('');
	const [totalHihlight, setTotalHighlight] = useState<null | number>(null);
	const copy = useCallback(symbols => {
		navigator.clipboard.writeText(symbols);
	}, []);
	let newOpar = opar;
	const changeColor = useCallback((index: number) => {
		const highlight = document.getElementsByTagName('mark');
		if (highlight.length !== 0) {
			Array.from(highlight).forEach((el, i) => {
				if (i !== index) {
					el.style.backgroundColor = '#FFEC39';
				} else {
					el.style.backgroundColor = '#FFAB18';
				}
			});
		}
	}, []);
	const updateCounter = useCallback((isVisible: boolean) => {
		const highlight = document.getElementsByTagName('mark').length;
		setTotalHighlight(isVisible ? highlight : null);
	}, []);

	const find = useCallback(
		e => {
			let valueToSearch = '';
			const allContent = Array.from(document.getElementsByClassName('contentEditable'));
			if (typeof e === 'string') {
				valueToSearch = e.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
				setInputValue(e);
			} else {
				valueToSearch = e.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
				setInputValue(e.target.value);
			}

			var re = new RegExp(valueToSearch, 'g');
			allContent.forEach((el, index) => {
				if (valueToSearch.length > 0) {
					el.innerHTML = opar[index].text.replace(re, `<mark style="background-color:#FFEC39">$&</mark>`);
				} else {
					el.innerHTML = opar[index].text;
				}
				updateCounter(typeof e === 'string' ? e.length !== 0 : e.target.value.length !== 0);
				changeColor(currentIndex);
			});
		},
		[changeColor, currentIndex, opar, updateCounter]
	);

	const replaceAll = useCallback(
		(e, newValue: string) => {
			e.stopPropagation();
			if (!totalHihlight || totalHihlight === 0) {
				return;
			}

			const allContent = Array.from(document.getElementsByClassName('contentEditable'));
			const valueToReplace = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
			var re = new RegExp(valueToReplace, 'g');
			allContent.forEach((el, index) => {
				if (valueToReplace.length > 0) {
					el.innerHTML = opar[index].text.replace(re, newValue);
				}
				updateCounter(inputValue.length !== 0);
				updateMemoContent({
					...newOpar,
					[index]: {
						text: newOpar[index].text.replace(re, newValue),
						start_time: newOpar[index].start_time
					}
				});
				updateMemoTextRef({
					...newOpar,
					[index]: {
						text: newOpar[index].text.replace(re, newValue),
						start_time: newOpar[index].start_time
					}
				});
				// eslint-disable-next-line react-hooks/exhaustive-deps
				newOpar = {
					...newOpar,
					[index]: {
						text: newOpar[index].text.replace(re, newValue),
						start_time: newOpar[index].start_time
					}
				};
			});
		},
		[inputValue, opar, totalHihlight, updateMemoContent, updateMemoTextRef]
	);

	const stingToReplace = useCallback(e => {
		setNewValue(e.target.value);
	}, []);

	const updateContent = useCallback(
		(newValue: string) => {
			const myMarkselected = document.getElementsByTagName('mark')[currentIndex];
			const allContent = Array.from(document.getElementsByClassName('contentEditable'));

			allContent.forEach((el, index) => {
				el.innerHTML = el.innerHTML.replace(myMarkselected.outerHTML, newValue);
				let newElementContent = el.innerHTML.replace(myMarkselected.outerHTML, newValue);

				updateMemoContent({
					...newOpar,
					[index]: {
						text: newElementContent,
						start_time: newOpar[index].start_time
					}
				});
				updateMemoTextRef({
					...newOpar,
					[index]: {
						text: newElementContent,
						start_time: newOpar[index].start_time
					}
				});
				// eslint-disable-next-line react-hooks/exhaustive-deps
				newOpar = {
					...newOpar,
					[index]: {
						text: newElementContent,
						start_time: newOpar[index].start_time
					}
				};
			});
		},
		[currentIndex, newOpar, updateMemoContent, updateMemoTextRef]
	);

	const changeActiveIndex = useCallback(
		direction => {
			switch (direction) {
				case -1:
					if (currentIndex === 0 && totalHihlight) {
						setCurrentIndex(totalHihlight - 1);
					} else {
						setCurrentIndex(currentIndex + direction);
					}
					break;
				case +1:
					if (totalHihlight && currentIndex === totalHihlight - 1) {
						setCurrentIndex(0);
					} else {
						setCurrentIndex(currentIndex + direction);
					}
					break;
			}
		},
		[currentIndex, totalHihlight]
	);

	const replaceSingle = useCallback(
		async (e, newValue: string) => {
			if (!totalHihlight || totalHihlight === 0) {
				return;
			}
			await updateContent(newValue);
			updateCounter(inputValue.length !== 0);
			setTimeout(() => {
				if (document.getElementsByTagName('mark')[0]) {
					document.getElementsByTagName('mark')[0].style.backgroundColor = '#FFAB18';
				}
			}, 0);
		},
		[totalHihlight, updateContent, updateCounter, inputValue.length]
	);

	useEffect(() => {
		changeColor(currentIndex);
	}, [changeColor, currentIndex]);

	const resetFindAndReplace = useCallback(
		(e?) => {
			if (e && (e.length === 0 || e[0] !== '0000')) {
				const inputs = document.getElementsByTagName('input');
				Array.from(inputs).forEach(input => {
					input.value = '';
				});
				const allContent = Array.from(document.getElementsByClassName('contentEditable'));
				setInputValue('');
				setCurrentIndex(0);
				setTotalHighlight(null);
				allContent.forEach((el, index) => {
					el.innerHTML = opar[index].text;
				});
			} else {
				const inputs = document.getElementsByTagName('input');
				Array.from(inputs).forEach(input => {
					input.value = '';
				});
				setInputValue('');
				setCurrentIndex(0);
				setTotalHighlight(null);
			}
		},
		[opar]
	);

	useEffect(() => {
		resetFindAndReplace();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTab]);

	return (
		<StyledEditPanel>
			<Accordion allowZeroExpanded={true} onChange={e => resetFindAndReplace(e)}>
				{/* <AccordionItem uuid="0000">
					<AccordionItemHeading>
						<AccordionItemButton>Trova e sostituisci</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<div className="input-container">
							<div className="input-container__search">
								<label>Trova:</label>
								<input onInput={e => find(e)} />{' '}
								{totalHihlight !== null && (
									<>
										<div>
											<button
												className="navigator down"
												disabled={totalHihlight === null || totalHihlight === 0}
												onClick={() => changeActiveIndex(-1)}
											></button>
										</div>
										<div>
											<button
												className="navigator up"
												disabled={totalHihlight === null || totalHihlight === 0}
												onClick={() => changeActiveIndex(+1)}
											></button>
										</div>
										<span>
											{totalHihlight === 0 ? 0 : currentIndex + 1}/{totalHihlight}
										</span>
									</>
								)}
							</div>
							<label>Sostituisci:</label>
							<input onInput={e => stingToReplace(e)} />
						</div>
						<div className="button-container">
							<Button variant="flat" onClick={e => replaceAll(e, newValue)}>
								Replace all
							</Button>
							<Button variant="flat" onClick={e => replaceSingle(e, newValue)}>Replace single</Button>
						</div>
					</AccordionItemPanel>
				</AccordionItem> */}
				<AccordionItem uuid="0001">
					<AccordionItemHeading>
						<AccordionItemButton>Simboli</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						{maths.map((math, i) => {
							return (
								<Button
									variant="flat"
									className="symbols vm-tooltip"
									key={i}
									onClick={() => copy(math.value)}
								>
									<Latex>{`$${math.text}$`}</Latex>
								</Button>
							);
						})}
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem uuid="0002">
					<AccordionItemHeading>
						<AccordionItemButton>Lettere greche</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						{minLetters.map((letter, i) => {
							return (
								<Button variant="flat" className="vm-tooltip" key={i} onClick={() => copy(letter.value)}>
									<Latex>{`$${letter.text}$`}</Latex>
								</Button>
							);
						})}
						<br />
						{maxLetters.map((letter, i) => {
							return (
								<Button variant="flat" className="vm-tooltip" key={i} onClick={() => copy(letter.value)}>
									<Latex>{`$${letter.text}$`}</Latex>
								</Button>
							);
						})}
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem uuid="0003">
					<AccordionItemHeading>
						<AccordionItemButton>Frecce</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						{arrows.map((arrow, i) => {
							return (
								<Button variant="flat" className="vm-tooltip" key={i} onClick={() => copy(arrow.value)}>
									<Latex>{`$${arrow.text}$`}</Latex>
								</Button>
							);
						})}
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem uuid="0004">
					<AccordionItemHeading>
						<AccordionItemButton>
							Teoremi
							<AlertCircle color="#3761b6" />
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						{theorem.map((theo, i) => {
							return (
								<Button
									variant="flat"
									className="templates vm-tooltip"
									key={i}
									onClick={() => copy(theo)}
								>
									Teorema Template
								</Button>
							);
						})}
						<br />
						<small>Gli elementi saranno copiati con commento</small>
					</AccordionItemPanel>
				</AccordionItem>
				<AccordionItem uuid="0005">
					<AccordionItemHeading>
						<AccordionItemButton>
							Tabelle
							<AlertCircle color="#3761b6" />
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						{table.map((tab, i) => {
							return (
								<Button
									variant="flat"
									className="templates vm-tooltip"
									key={i}
									onClick={() => copy(tab)}
								>
									Tabella Template
								</Button>
							);
						})}
						<br />
						<small>Gli elementi saranno copiati con commento</small>
					</AccordionItemPanel>
				</AccordionItem>
			</Accordion>
		</StyledEditPanel>
	);
}

const StyledEditPanel = styled.div`
	button {
		height: 3rem;
		width: 3rem;
		font-size: 17pt;
		&.symbols {
			font-size: 10pt;
		}
		&.templates {
			width: auto;
			font-size: 10pt;
		}
		.katex-display {
			margin: 0;
		}
		&.btn-flat {
			color: #1c325e;
			background: rgb(55, 97, 182, 0.1);
			margin: 0 0.5rem 0.5rem 0;
			transition: background 100ms ease-in-out;
			position: relative;
			&:hover {
				background: rgb(55, 97, 182, 0.3);
			}
		}
		&.vm-tooltip {
			&:active {
				background: #3761b6;
				color: #fff;
			}
			&:after {
				content: 'Copy';
				background-color: #444444;
				border-radius: 10px;
				font-size: 12px;
				color: #fff;
				display: none;
				padding: 10px 15px;
				position: absolute;
				text-align: center;
				z-index: 999;
			}
			&:hover {
				background: rgb(55, 97, 182, 0.3);
				&:after {
					display: block;
				}
			}
		}
	}
	.accordion__button > svg {
		width: 20px;
		margin-left: 0.5rem;
	}
	.button-container {
		button {
			width: auto;
			height: auto;
			font-size: 12pt;
			margin-top: 1rem;
		}
	}
	.input-container__search {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
	}
	label {
		margin: 0 1rem 0 0;
	}
	.navigator {
		border: none;
		outline: none;
		background: rgba(0, 0, 0, 0);
		width: auto;
		height: auto;
		&::before {
			display: inline-block;
			content: '';
			height: 10px;
			width: 10px;
			margin-right: 12px;
			border-bottom: 2px solid black;
			border-right: 2px solid black;
		}
		&.down {
			transform: rotate(45deg);
			margin: 0 0 0 1rem;
		}
		&.up {
			transform: rotate(225deg);
			margin: 0 1rem 0 -1rem;
		}
	}
`;
