import styled from 'styled-components';

import PuffLoader from 'react-spinners/PuffLoader';

function Loader() {
	return <PuffLoader color={'#3761B6'} loading={true} size={200} />;
}
export const MainLoader = () => {
	return (
		<StyledRoller>
			<Loader />
		</StyledRoller>
	);
};

const StyledRoller = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
`;
