export const minLetters = [
	{
		value: '\\( \\alpha \\)',
		text: '$\\alpha$'
	},
	{
		value: '\\( \\beta \\)',
		text: '$\\beta$'
	},
	{
		value: '\\( \\gamma \\)',
		text: '$\\gamma$'
	},
	{
		value: '\\( \\delta \\)',
		text: '$\\delta$'
	},
	{
		value: '\\( \\epsilon \\)',
		text: '$\\epsilon$'
	},
	{
		value: '\\( \\zeta \\)',
		text: '$\\zeta$'
	},
	{
		value: '\\( \\eta \\)',
		text: '$\\eta$'
	},
	{
		value: '\\( \\theta \\)',
		text: '$\\theta$'
	},
	{
		value: '\\( \\iota \\)',
		text: '$\\iota$'
	},
	{
		value: '\\( \\kappa \\)',
		text: '$\\kappa$'
	},
	{
		value: '\\( \\lambda \\)',
		text: '$\\lambda$'
	},
	{
		value: '\\( \\mu \\)',
		text: '$\\mu$'
	},
	{
		value: '\\( \\nu \\)',
		text: '$\\nu$'
	},
	{
		value: '\\( \\xi \\)',
		text: '$\\xi$'
	},
	{
		value: 'o',
		text: 'o'
	},
	{
		value: '\\( \\pi \\)',
		text: '$\\pi$'
	},
	{
		value: '\\( \\rho \\)',
		text: '$\\rho$'
	},
	{
		value: '\\( \\sigma \\)',
		text: '$\\sigma$'
	},
	{
		value: '\\( \\tau \\)',
		text: '$\\tau$'
	},
	{
		value: '\\( \\upsilon \\)',
		text: '$\\upsilon$'
	},
	{
		value: '\\( \\varphi \\)',
		text: '$\\varphi$'
	},
	{
		value: '\\( \\chi \\)',
		text: '$\\chi$'
	},
	{
		value: '\\( \\psi \\)',
		text: '$\\psi$'
	},
	{
		value: '\\( \\omega \\)',
		text: '$\\omega$'
	}
];
export const maxLetters = [
	{
		value: 'A',
		text: 'A'
	},
	{
		value: 'B',
		text: 'B'
	},
	{
		value: '\\( \\Gamma \\)',
		text: '$\\Gamma$'
	},
	{
		value: '\\( \\Delta \\)',
		text: '$\\Delta$'
	},
	{
		value: 'E',
		text: 'E'
	},
	{
		value: 'Z',
		text: 'Z'
	},
	{
		value: 'H',
		text: 'H'
	},
	{
		value: '\\( \\Theta \\)',
		text: '$\\Theta$'
	},
	{
		value: 'I',
		text: 'I'
	},
	{
		value: 'K',
		text: 'K'
	},
	{
		value: '\\( \\Lambda \\)',
		text: '$\\Lambda$'
	},
	{
		value: 'M',
		text: 'M'
	},
	{
		value: 'N',
		text: 'N'
	},
	{
		value: '\\( \\Xi \\)',
		text: '$\\Xi$'
	},
	{
		value: 'O',
		text: 'O'
	},
	{
		value: '\\( \\Pi \\)',
		text: '$\\Pi$'
	},
	{
		value: 'P',
		text: 'P'
	},
	{
		value: '\\( \\Sigma \\)',
		text: '$\\Sigma$'
	},
	{
		value: 'T',
		text: 'T'
	},
	{
		value: '\\( \\Upsilon \\)',
		text: '$\\Upsilon$'
	},
	{
		value: '\\( \\Phi \\)',
		text: '$\\Phi$'
	},
	{
		value: 'X',
		text: 'X'
	},
	{
		value: '\\( \\Psi \\)',
		text: '$\\Psi$'
	},
	{
		value: '\\( \\Omega \\)',
		text: '$\\Omega$'
	}
];
export const maths = [
	{
		value: '\\( \\log \\)',
		text: '$\\log$'
	},
	{
		value: '\\( \\int \\)',
		text: '$\\int$'
	},
	{
		value: '\\( \\sum \\)',
		text: '$\\sum$'
	},
	{
		value: '\\( \\prod \\)',
		text: '$\\prod$'
	},
	{
		value: '\\( \\oint \\)',
		text: '$\\oint$'
	},
	{
		value: '\\( \\equiv \\)',
		text: '$\\equiv$'
	},
	{
		value: '\\( \\leq \\)',
		text: '$\\leq$'
	},
	{
		value: '\\( \\geq \\)',
		text: '$\\geq$'
	},
	{
		value: '\\( \\propto \\)',
		text: '$\\propto$'
	},
	{
		value: '\\( \\in \\)',
		text: '$\\in$'
	},
	{
		value: '\\( \\ni \\)',
		text: '$\\ni$'
	},
	{
		value: '\\( \\neq \\)',
		text: '$\\neq$'
	}
];

export const arrows = [
	{
		value: '\\( \\leftarrow \\)',
		text: '$\\leftarrow$'
	},
	{
		value: '\\( \\Leftarrow \\)',
		text: '$\\Leftarrow$'
	},
	{
		value: '\\( \\rightarrow \\)',
		text: '$\\rightarrow$'
	},
	{
		value: '\\( \\Rightarrow \\)',
		text: '$\\Rightarrow$'
	},
	{
		value: '\\( \\leftrightarrow \\)',
		text: '$\\leftrightarrow$'
	},
	{
		value: '\\( \\rightleftharpoons \\)',
		text: '$\\rightleftharpoons$'
	}
];

export const theorem = [
`\\(% \\begin{thm}[Inserisci il nome del teorema] \\) \
\\(% \\label{etichettaTeorema} \\) \
\\(% Body of theorem. \\) \
\\(% \\end{thm}\\) `
];

export const table = [
`\\(% \\begin{center} \\) \
\\(% \\begin{tabular}{lll} \\) \
\\(% \\toprule % per inserire la riga di intestazione \\) \
\\(% Colonna1 & Colonna2 & Colonna3 \\\\ \\) \
\\(% \\midrule \\) \
\\(% testo & testo & testo \\\\ \\) \
\\(% testo & testo & testo \\\\ \\) \
\\(% testo & testo & testo \\\\ \\) \
\\(% \\bottomrule \\) \
\\(% \\end{tabular} \\) \
\\(% \\end{center}\\) `
];
