import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { FileText, Sigma, Box, RefreshCw } from 'lucide-react';

import { FormulaPanel } from '../components/FormulaPanel';
import { FormuleType } from '../types/job.types';
import { EditPanel } from './EditPanel';
import LatexPreview from './LatexPreview'
import PerfectScrollbar from 'react-perfect-scrollbar';

export const TabSelector = ({
	isActive,
	children,
	onClick
}: {
	isActive: boolean;
	children: React.ReactNode;
	onClick: () => void;
}) => (
	<StyledTabSelectorButton isActive={isActive} onClick={onClick}>
		{children}
	</StyledTabSelectorButton>
);

const StyledTabSelectorButton = styled.button<{ isActive: boolean }>`
	width: 100%;
	height: 10vh;
	border: none;
	background-color: #fffffb;
	font-family: arial;
	font-size: 2.1vh;
	color: #4d4d4d;
	border-bottom: 5px solid transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		height: 20px;
		margin-right: 10px;
		.cls-1 {
			fill: #4d4d4d;
		}
	}
	:hover {
		background: rgb(55, 97, 182, 0.05);
	}
	${props =>
	props.isActive &&
	`
		border-bottom: 5px solid #3761B6;
		background: rgb(55,97,182,.1);
		color: #1C325E;
`		}
`;

function usePrevious<T>(value: T) {
	const ref = React.useRef<T>();

	React.useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}

export function useTabs<K extends string>(tabs: K[], defaultTab?: K | null) {
	const state = React.useState<K | null>();
	const [selectedTab, setSelectedTab] = state;

	const activeIndex = React.useMemo(() => {
		if (selectedTab) {
			return tabs.indexOf(selectedTab);
		}

		return -1;
	}, [selectedTab, tabs]);

	const previousActiveIndex = usePrevious(activeIndex);

	React.useEffect(() => {
		if (tabs.length === 0) {
			setSelectedTab(undefined);
			return;
		}

		if (selectedTab === null || (selectedTab && tabs.includes(selectedTab))) {
			return;
		}

		if (
			typeof previousActiveIndex === 'number' &&
			previousActiveIndex >= 0 &&
			(tabs[previousActiveIndex] || tabs[previousActiveIndex - 1])
		) {
			setSelectedTab(tabs[previousActiveIndex] || tabs[previousActiveIndex - 1]);

			return;
		}

		if (defaultTab === null) {
			return;
		}

		setSelectedTab(defaultTab && tabs.includes(defaultTab) ? defaultTab : tabs[0]);
	}, [selectedTab, defaultTab, tabs, previousActiveIndex, setSelectedTab]);

	return state;
}

export function TabPanel({
	children,
	render = 'lazy',
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	hidden: boolean;
	render?: 'always' | 'lazy';
}) {
	const [shouldRender, setShouldRender] = React.useState(render === 'always');

	React.useEffect(() => {
		if (props.hidden) {
			return;
		}

		setShouldRender(true);
	}, [props.hidden]);

	return <StyledContentTab {...props}>{shouldRender ? children : null}</StyledContentTab>;
}
const StyledContentTab = styled.div`
	margin-top: 10vh;
	height: 90vh;
`;

export function Tab({
	formulas,
	tmpFormula,
	onEditFormula,
	selectedTab,
	setSelectedTab,
	formulaSuggestions,
	selectedFormula,
	compileLatex,
	memoText,
	setMemoText,
	updateMemoTextRef,
	editorValue,
}: {
	formulas: FormuleType[];
	tmpFormula: Record<number, string>
	onEditFormula: any
	selectedTab: 'Preview' | 'Formula' | 'Edit' | null | undefined;
	setSelectedTab: (selectedTab: 'Preview' | 'Formula' | 'Edit' | null | undefined) => void;
	formulaSuggestions: any;
	selectedFormula: [number, number] | null;
	compileLatex: () => void;
	memoText: Record<
		number,
		{
			text: string;
			start_time: string;
		}
	>;
	setMemoText: (
		memoText: Record<
			number,
			{
				text: string;
				start_time: string;
			}
		>
	) => void;
	updateMemoTextRef: (newMemo: any) => void;
	editorValue: any
}) {
	useEffect(() => {
		const latex = document.getElementById('latex');
		if (latex) {
			latex.style.display = selectedTab === 'Preview' ? 'block' : 'none';
		}
	}, [selectedTab]);
	useEffect(() => {
		if (selectedTab === 'Preview') {
			compileLatex();
		}
	});
	const selectAndCompile = useCallback(() => {
		setSelectedTab('Preview');
		compileLatex();
	}, [compileLatex, setSelectedTab]);

	const selectAndCompileFormula = useCallback(() => {
		compileLatex();
		setSelectedTab('Formula');
	}, [compileLatex, setSelectedTab]);

	const selectAndCompileEdit = useCallback(() => {
		compileLatex();
		setSelectedTab('Edit');
	}, [compileLatex, setSelectedTab]);

	const [error, setError] = React.useState(null);

	return (
		<>
			<StyledNav>
				<TabSelector isActive={selectedTab === 'Preview'} onClick={() => selectAndCompile()}>
					<FileText />
					Preview
				</TabSelector>
				{formulas.length !== 0 && (
					<TabSelector isActive={selectedTab === 'Formula'} onClick={() => selectAndCompileFormula()}>
						<Sigma />
						Formula
					</TabSelector>
				)}
				<TabSelector isActive={selectedTab === 'Edit'} onClick={() => selectAndCompileEdit()}>
					<Box />
					Edit
				</TabSelector>
			</StyledNav>
			<div>
				<TabPanel hidden={selectedTab !== 'Preview'}>
					<StyledTab>
						<PerfectScrollbar>
							{/* <button className="compiler" onClick={() => compileLatex()}>
								<RefreshCw />
							</button> */}
							<LatexPreview
								value={editorValue}
							/>
						</PerfectScrollbar>
					</StyledTab>
				</TabPanel>
				<TabPanel hidden={selectedTab !== 'Formula'}>
					{formulas.length !== 0 && (
						<FormulaPanel formulas={formulas} tmpFormula={tmpFormula} onEditFormula={onEditFormula} selectedFormula={selectedFormula} formulaSuggestions={formulaSuggestions} />
					)}
				</TabPanel>
				<TabPanel hidden={selectedTab !== 'Edit'}>
					<StyledTab>
						<PerfectScrollbar>
							<EditPanel opar={memoText} selectedTab={selectedTab} updateMemoContent={setMemoText} updateMemoTextRef={updateMemoTextRef} />
						</PerfectScrollbar>
					</StyledTab>
				</TabPanel>
			</div>
		</>
	);
}

//

const StyledNav = styled.nav`
	display: flex;
	border-bottom: 1px solid #1c325e;
	background: white;
	width: 50%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1;
`;

///
const StyledTab = styled.div`
	position: relative;
	overflow-y: hidden;
	height: 100%;
	/* .compiler {
		height: 3em;
		width: 3em;
		border-radius: 50%;
		position: absolute;
		top: 1em;
		left: 1em;
		z-index: 1;
		background: rgba(0, 0, 0, 0);
		border: 2px solid rgba(0, 0, 0, 0);
		cursor: pointer;
		&:hover {
			border: 2px solid #3761b6;
		}
		svg {
			height: 100%;
			width: 100%;
		}
	} */
`;
