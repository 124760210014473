import styled from 'styled-components';
import { ReactComponent as IconOverlay } from '../assets/too_small.svg';
export function MobileOverlay() {
	return (
		<StyledMobileOverlay className="mobile-overlay">
            <IconOverlay/>
            <span>Oops! VoiceMath è stato disegnato per supportare device desktop o tablet, in modalità landscape</span>

		</StyledMobileOverlay>
	);
}
const StyledMobileOverlay = styled.div`
	display: flex;
	height: 100%;
	position: absolute;
	background: #3761B6;
    color:#fffffb;
	width: 100%;
	z-index: 9999;
	align-items: center;

    flex-direction: column;
    justify-content: center;
    svg{
        height:40vh;
    }
    span{
        width:40%;
        font-family: 'Titillium Web', sans-serif;
		font-size: 18pt;
        text-align:center;
    }
    @media only screen and (min-width: 820px) {
		justify-content: space-evenly;
        flex-direction: row;
	}
	@media only screen and (min-width: 1024px) {
		display: none;
	}
`;
