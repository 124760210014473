import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { deleteJob } from '../services/useServicesList';
import { Card, Button } from 'react-bootstrap';
import { CheckCircle2, XCircle } from 'lucide-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainDialog from './MainDialog';
import toast, { Toaster } from 'react-hot-toast';
import RingLoader from 'react-spinners/RingLoader';
import ReactTimeAgo from 'react-time-ago';
import { PaginatedItems } from '../components/Pagination';

function Loader() {
	return <RingLoader color={'#3761B6'} loading={true} size={30} />;
}
export function PreviousJobs({
	jobs,
	getJob,
	totalJobs,
	getAllJobsCallback,
}: {
	jobs: any;
	getJob: (jobId: string) => void;
	totalJobs: number;
	getAllJobsCallback: (startIndex?: number, endIndex?: number,activePage?:number) => void;
}) {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedJob, setSelectedJob] = useState<any>(null);

	const startEditing = useCallback(
		(jobId: string) => {
			getJob(jobId);
		},
		[getJob]
	);

	const handleDelete = async (jobId: string) => {
    const response = await deleteJob(jobId);
		if (response.status === 200) {
				toast.success('Elaborazione eliminata con successo');
		} else {
				toast.error('Errore durante l\'eliminazione dell\'elaborazione');
		}
		getAllJobsCallback();
  };

	function openDeleteModal(jobId: string) {
		setSelectedJob(jobId);
		setIsDialogOpen(true);
	}

	return (
		<StyledCard>
			<div className='job-header'>
				<div className="count">Elaborazioni totali: <b>{totalJobs}</b></div>
				<PaginatedItems itemsPerPage={10} totalItem={totalJobs} getAllJobsCallback={getAllJobsCallback}/>
			</div>
			<PerfectScrollbar>
				{jobs.length === 0 && (
					<Card>
						<Card.Header>
							<div className="no-prev-job">NON SONO PRESENTI ELABORAZIONI</div>
						</Card.Header>
						<Card.Body>
							<Card.Text>Seleziona un file audio/video e avvia la prima elaborazione</Card.Text>
						</Card.Body>
					</Card>
				)}
				{jobs.map((job: any) => {
					return (
						<Card key={job.job_id}>
							<Card.Header>
								<span className="status">
									{job.status === 'COMPLETED' && <CheckCircle2 color="#57a773" />} {job.status === 'BUSY' && <Loader />}{' '}
									{job.status === 'ERROR' && <XCircle color="#d1603d" />}
									{job.status}
								</span>
								<span className="job"> {job.job_id}</span>
							</Card.Header>
							<Card.Body>
								{job.input_data.audio_paths.length !== 0 && (
									<Card.Text>
										<b>AUDIO CARICATO</b>
										<br /> {job.input_data.audio_paths.toString().replace(/^.*[\\\/]/, '')}
									</Card.Text>
								)}
								{job.input_data.video_paths.length !== 0 && (
									<Card.Text>
										<b>VIDEO CARICATO</b>
										<br />
										{job.input_data.video_paths.toString().replace(/^.*[\\\/]/, '')}
									</Card.Text>
								)}
								{job.input_data.pdf_paths.length !== 0 && (
									<Card.Text>
										<b>PDF CARICATI</b>
										{job.input_data.pdf_paths.map((pdf: any, index: number) => {
											return <li key={index}>{pdf.toString().replace(/^.*[\\\/]/, '')}</li>;
										})}
									</Card.Text>
								)}
									<div className='card-actions'>
									{job.status === 'COMPLETED' && (
										<>
											<Button variant="flat" onClick={() => startEditing(job.job_id)}>
												START EDITING
											</Button>
											<Button
												variant="secondary"
												onClick={() => openDeleteModal(job.job_id)}
												disabled={job.status === 'BUSY' || job.status === 'LOADED'}
											>
												DELETE
											</Button>
										</>
									)}
									{job.status === 'ERROR' && (
										<Button
											variant="secondary"
											onClick={() => openDeleteModal(job.job_id)}
											disabled={job.status === 'BUSY' || job.status === 'LOADED'}
										>
											DELETE
										</Button>
									)}
									</div>
							</Card.Body>
							<Card.Footer className="text-muted">
								<ReactTimeAgo date={new Date(job.loaded_time * 1000)} locale="en-US" className="date" />
							</Card.Footer>
						</Card>
					);
				})}
			</PerfectScrollbar>
			<MainDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<MainDialog.Content
						title="Cancella elaborazione"
						description={`Sei sicuro/a di voler cancellare l'elaborazione con id ${selectedJob}?`}
					>
						<hr />
						<div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
							<Button
								variant="flat"
								style={{ border: '1px solid rgb(55, 97, 182)' }}
								onClick={() => {
									setIsDialogOpen(false);
								}}
							>
								Annulla
							</Button>
							<Button
								variant="flat"
								style={{ backgroundColor: 'rgb(55, 97, 182)', color: 'white' }}
								type="submit"
								onClick={() => {
									setIsDialogOpen(false);
									handleDelete(selectedJob);
								}}
							>
								Si, elimina elaborazione
							</Button>
						</div>
					</MainDialog.Content>
			</MainDialog>
			<Toaster
				position="bottom-right"
				reverseOrder={false}
				toastOptions={{
					duration: 5000,
				}}
			/>
		</StyledCard>
	);
}

export const Modale = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledCard = styled.div`
	position: relative;
	.job-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 30px;
		ul li {
			font-size: 16pt;
		}
		ul li a {
			font-size: 14pt;
		}
	}
	.count{
		b{
			font-size: 1.2rem;
		}
		font-size: 1.1rem;
	}
	height: 100%;
	flex: 1;
	.scrollbar-container{
		margin: 1em 0 0;
		height: calc(100% - 54px - 1em);
	}
	.card {
		margin: 0 1rem 2rem 0;
		border: 1px solid #1c325e;
		&:last-child {
			margin-bottom: 0;
	}
	.card-header {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #1c325e;
		.status {
			display: flex;
			align-items: center;
			font-size: 1.4rem;
			svg {
				width: 30px;
			}
			span,
			svg {
				margin-right: 1rem;
			}
		}
		.job {
			max-width: 10rem;
			font-size: 1.4rem;
			text-overflow: ellipsis;
			height: 2rem;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	.card-text {
		font-family: Verdana;
		font-size: 1rem;
		b {
			font-family: Verdana;
			font-size: 1.2rem;
		}
		li {
			font-family: Verdana;
			font-size: 1rem;
			list-style-type: disclosure-closed;
			line-break: anywhere;
		}
	}
	.card-actions {
		display: flex;
		gap: 12px;
		.btn-flat:first-child {
			background-color: #3761b6;
		}
	}
	.card-footer,
	.date {
		font-size: 1rem;
	}
	.card-footer {
		border-top: 1px solid #1c325e;
	}

	.btn {
		color: white;
		font-size: 1.2rem;
		transition: opacity 0.2s ease-in-out;
		&:hover {
			opacity: 0.8;
		}
	}
`;
