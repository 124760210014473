import { Jobs } from './pages/Jobs';
import { useEffect, useState, useCallback } from 'react';
import Edit from './pages/Edit';
import { ElaborationType } from './types/job.types';
import { ReactComponent as Ico404 } from './assets/404.svg';
import styled from 'styled-components';
import { MainLoader } from './components/LoaderMain';
import { MobileOverlay } from './components/MobileOverlay';
import { useTranslation } from 'react-i18next';
import { Header } from './components/Header';
import { checkCookie } from './helpers/utils';
import { Button } from 'react-bootstrap';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
	window.onbeforeunload = event => {
		// Show prompt based on state
		if (showExitPrompt) {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				e.returnValue = '';
			}
			return '';
		}
	};
};

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [error, setError] =useState<any>({error:"",description:"", button:null})
	const [applicationState, setApplicationState] = useState<'NOT_SELECTED' | 'SELECTED' | 'ERROR' | null>(
		'NOT_SELECTED'
	);
	const [showExitPrompt, setShowExitPrompt] = useState(false);

	window.onload = function () {
		initBeforeUnLoad(showExitPrompt);
	};

	//Selected Job
	const [elaboration, setElaboration] = useState<ElaborationType>();

	//Callback per errore
	const errorState = useCallback((e:any) => {
		setApplicationState('ERROR');
		const description=e.message.indexOf("403")!==-1?"Non hai i permessi, contatta l'amministratore":e.message.indexOf("401")!==-1?"Sembra tu non sia autenticato": "Ricarica la pagina, se il proplema persiste contatta l'amministratore"
		setError({error:e.message,description,button:e.message.indexOf("403")!==-1 })
	}, []);

	//Callback per ripristinare situazione iniziale
	const resetState = useCallback(() => {
		setApplicationState('NOT_SELECTED');
		setElaboration(undefined);
	}, []);

	// Re-Initialize the onbeforeunload event listener
	useEffect(() => {
		initBeforeUnLoad(applicationState === 'SELECTED');
		setShowExitPrompt(applicationState === 'SELECTED');
	}, [applicationState]);

	useEffect(() => {
		const latex = document.getElementById('latex');
		if (
			latex &&
			!(
				applicationState === 'SELECTED' &&
				elaboration &&
				'status' in elaboration &&
				elaboration.status === 'COMPLETED'
			)
		) {
			latex.style.display = 'none';
		}
	}, [elaboration, applicationState]);

	useEffect(() => {
		//Redirect to login if user is not logged in
		const login= checkCookie()
		if (!login) {
				document.location.href= `/login`;
		}else{
			setIsLoggedIn(login)
		}
	}, []);

	return (
		<>
			{isLoggedIn && (
				<>
					<MobileOverlay />
					<Header
						setIsLoggedIn={setIsLoggedIn}
						isLoggedIn={isLoggedIn}
						isVisible={!isLoggedIn || (isLoggedIn && applicationState !== 'SELECTED')}
					/>
					{applicationState === null ? (
						<MainLoader />
					) : applicationState === 'NOT_SELECTED' ? (
						<Jobs
							setApplicationState={setApplicationState}
							errorState={errorState}
							setReady={setElaboration}
						/>
					) : applicationState === 'SELECTED' &&
					  elaboration &&
					  'status' in elaboration &&
					  elaboration.status === 'COMPLETED' ? (
						<Edit
							setIsLoggedIn={setIsLoggedIn}
							data={elaboration.output_data}
							hasPdf={elaboration.input_data.pdf_paths.length !== 0}
							hasAudio={
								elaboration.input_data.audio_paths.length !== 0 ||
								elaboration.input_data.video_paths.lenght !== 0
							}
							jobId={elaboration.job_id}
							resetState={resetState}
						/>
					) : (
						<div>
							<StyledErrorPage>
								<Ico404/>
								<div>
									<h1 aria-label="Errore">{error.error}</h1>
									<p aria-label="Ricarica la pagina">{error.description}</p>
									{error.button &&<Button onClick={()=>{document.location.href= `/login`}} variant="flat">Riprova</Button>}
								</div>
							</StyledErrorPage>
						</div>
					)}
				</>
			)}
		</>
	);
}

export default App;

export const StyledErrorPage = styled.div`
	width: 100%;
	background: linear-gradient(135deg,#e8effd,white);
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	height: 100vh;

	svg {
		height: 50%;
	}
	div {
		width: 40%;
		h1 {
			font-size: 4.5vh;
		}
		p {
			margin-top:10%;
			font-size: 3.5vh;
			color: grey;
		}
		margin-left: 10%;
		display: flex;
		display: flex;
		flex-direction: column;
	}
	.btn-flat {
        background-color: #3761B6;
        color: white;
        font-size:1.2rem;
		width: 30%;
		margin-top: 2rem;
      }
`;

