import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';

export function Sidebar({
	pageWrapId,
	outerContainerId,
	download,
	setIsDialogOpen,
	save,
	logout
}: {
	pageWrapId: string;
	outerContainerId: string;
	download: () => void;
	setIsDialogOpen: (value: boolean) => void;
	save: () => void;
	logout: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false)
  const handleStateChange = (state: { isOpen: boolean | ((prevState: boolean) => boolean); }) => setIsOpen(state.isOpen)

	const handleReturn = () => {
		setIsDialogOpen(true);
		setIsOpen(false);
	};

	const handleSave = () => {
		save();
		setIsOpen(false);
	}

	const handleDownload = () => {
		download();
		setIsOpen(false);
	}

	return (
		<Menu isOpen={isOpen} onStateChange={handleStateChange}>
			<StyledMenu>
				<div className="main-actions">
					<span className="menu-item" onClick={handleReturn}>
						Home
					</span>
					<span className="menu-item" onClick={handleSave}>
						Save
					</span>
					<span className="menu-item" onClick={handleDownload}>
						Export
					</span>
				</div>
				{/* <span className="menu-item footer" onClick={logout}>
					Logout
				</span> */}
			</StyledMenu>
		</Menu>
	);
}

const StyledMenu = styled.div`
	display: flex !important;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	.main-actions {
		display: flex !important;
		flex-direction: column;
		padding: 0.8em;
		.menu-item {
			margin-bottom: 1em;
		}
	}
	.footer {
		border-top: 1px solid #d1d1d1;
		padding: 1rem 0.8em 0;
    font-size: 15pt;
	}
`;
