import { Button, Form } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

import { useMemo, useCallback, useState } from 'react';
import MainDialog from './MainDialog';

export function FilesList({
	files,
	addJob,
	hasAvailableSpace
}: {
	files: any;
	addJob: (audioVideoPath: string, pdfPath: string[]) => void;
	hasAvailableSpace: boolean;
}) {
	const audioVideoFiles = useMemo(() => {
		return [...files.video_paths, ...files.audio_paths];
	}, [files]);
	const defaultCheck = useCallback(() => {
		if (!audioVideoFiles) {
			return '';
		}
		if (audioVideoFiles.length === 0) {
			return '';
		}
		return audioVideoFiles[0];
	}, [audioVideoFiles]);
	const [audioVideoPath, setAudioVideoPath] = useState<string>(defaultCheck());

	const [pdfPath, setPdfPath] = useState<string[]>([]);

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const submit = useCallback(
		e => {
			e.preventDefault();
			addJob(audioVideoPath, pdfPath);
		},
		[addJob, audioVideoPath, pdfPath]
	);

	const changeMediaFile = useCallback(e => {
		setAudioVideoPath(e.target.value);
	}, []);
	const changePdfFile = useCallback(
		e => {
			const removing = pdfPath.indexOf(e.target.value) !== -1;
			if (removing) {
				const newPath = pdfPath.filter(elem => {
					return elem !== e.target.value;
				});
				return setPdfPath(newPath);
			}
			return setPdfPath([...pdfPath, e.target.value]);
		},
		[pdfPath]
	);

	function getFileName(path: string) {
		return path.replace(/^.*[\\\/]/, '');
	}

	return (
		<StyledCard>
			<Form>
				<Form.Group className="form-group">
					<h2>Seleziona un file audio o video</h2>
					<div className="container">
						<PerfectScrollbar>
							{audioVideoFiles &&
								audioVideoFiles.map((file: any, index: number) => (
									<div key={index} className="mb-3">
										<Form.Check
											label={file.toString().replace(/^.*[\\\/]/, '')}
											value={file}
											name="video-audio_path"
											type={'radio'}
											id={file}
											defaultChecked={index === 0}
											onChange={changeMediaFile}
										/>
									</div>
								))}
						</PerfectScrollbar>
					</div>
				</Form.Group>
				<Form.Group className="form-group">
					<h2>Seleziona uno o più file PDF</h2>
					<div className="container">
						<PerfectScrollbar>
							{files.pdf_paths &&
								files.pdf_paths.map((file: any, index: number) => (
									<div key={index} className="mb-3">
										<Form.Check
											label={file.toString().replace(/^.*[\\\/]/, '')}
											value={file}
											name="pdf_paths"
											type={'checkbox'}
											id={file}
											onChange={changePdfFile}
										/>
									</div>
								))}
						</PerfectScrollbar>
					</div>
				</Form.Group>
				<Button
					variant="flat"
					size='lg'
					onClick={() => {
						setIsDialogOpen(true);
					}}
					disabled={!audioVideoFiles || audioVideoFiles.length === 0 || !hasAvailableSpace}
				>
					INIZIA NUOVA ELABORAZIONE
				</Button>
				<MainDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<MainDialog.Content
						title="Avviso nuova elaborazione"
						description={`Iniziare una nuova elaborazione con i file selezionati?`}
					>
						<ul>
							{audioVideoPath ? (
								<li>
									<b>File audio/video:</b> {getFileName(audioVideoPath)}
								</li>
							) : null}
							{pdfPath.length > 0 ? (
								<li>
									<b>File PDF:</b> {pdfPath.map(path => getFileName(path)).join(', ')}
								</li>
							) : null}
						</ul>
						<hr />
						<div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
							<Button
								variant="flat"
								style={{ border: '1px solid rgb(55, 97, 182)' }}
								onClick={() => {
									setIsDialogOpen(false);
								}}
							>
								Annulla
							</Button>
							<Button
								variant="flat"
								style={{ backgroundColor: 'rgb(55, 97, 182)', color: 'white' }}
								type="submit"
								onClick={(e: any) => {
									submit(e);
									setIsDialogOpen(false);
								}}
							>
								Si, inizia elaborazione
							</Button>
						</div>
					</MainDialog.Content>
				</MainDialog>
			</Form>
		</StyledCard>
	);
}

export const StyledCard = styled.div`
	height: 83vh;
	flex:1;
	@media screen and (max-width: 1370px) {
		width:40%;
	}
    form{
			margin-right:2rem;
			display: flex;
			flex-direction: column;
			margin-bottom:2rem;
			height: 100%;
			.form-group{
					height: calc((100% / 2) - 75px);
					margin-bottom:2rem;
					background: #ffffff;
					border: 1px solid #1C325E;
					border-style: groove;
					border-radius: 10px;
					h2{
							background:#1C325E;
							color:#ffffff;
							padding: 1rem;
							text-transform: uppercase;
							font-size:1.3rem;
							font-weight: bold;
							overflow:hidden;
							border-top-left-radius:9px;
							border-top-right-radius:9px;
					}
			.container{
				height: calc(100% - 4.5rem);
			}
      }
			.form-check{
					padding: 0 1rem;
			}
			.form-check input {
					display: none;
				}
			.form-check label {
				display: inline-block;
				background-color: #ffffff;
				border: 1px solid #3761B6;
				border-radius:10px;
				padding: 4px 11px;
				cursor: pointer;
				padding: 0.5rem 1rem;
				font-family: Verdana;
				font-size: 1rem;
				width: 100%;
				word-break: break-all;
			}
			.form-check input:checked+label {
				background-color: #3761B6;
				color: #ffffff;
			}
    }
    .btn-flat {
        background-color: #3761B6;
        color: white;
        font-size:1.2rem;
				margin-top: auto;
				margin-bottom: 1.5rem;
      }
`;
