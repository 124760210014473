export const BEGIN_LATEX_PREVIEW = `\\documentclass{article}

\\usepackage{comment, multicol}
\\usepackage{hyperref}

\\usepackage{calc,pict2e,picture}
\\usepackage{textgreek,amsmath,gensymb,stix}
\\newtheorem{thm}{Theorem}

\\begin{document}%
\\thispagestyle{empty}

\\begin{center}
\\Large{\\textbf{VOICEMATH}}
\\end{center}

`;

export const END_LATEX = `\\end{document}`;

export const LATEX_MACROS = [
  { "label": "\\Arrowvert", "type": "\\Arrowvert" },
  { "label": "\\Bbbk", "type": "\\Bbbk" },
  { "label": "\\Big", "type": "\\Big" },
  { "label": "\\Bigg", "type": "\\Bigg" },
  { "label": "\\Biggl", "type": "\\Biggl" },
  { "label": "\\Biggr", "type": "\\Biggr" },
  { "label": "\\Bigl", "type": "\\Bigl" },
  { "label": "\\Bigm", "type": "\\Bigm" },
  { "label": "\\Bigr", "type": "\\Bigr" },
  { "label": "\\Box", "type": "\\Box" },
  { "label": "\\Bumpeq", "type": "\\Bumpeq" },
  { "label": "\\Cap", "type": "\\Cap" },
  { "label": "\\cite[#1]{#2}", "type": "\\cite[#1]{#2}" },
  { "label": "\\cite{#1}", "type": "\\cite{#1}" },
  { "label": "\\Cup", "type": "\\Cup" },
  { "label": "\\DeclareMathOperator{#1}{#2}", "type": "\\DeclareMathOperator{#1}{#2}" },
  { "label": "\\Delta", "type": "\\Delta" },
  { "label": "\\Downarrow", "type": "\\Downarrow" },
  { "label": "\\Finv", "type": "\\Finv" },
  { "label": "\\Game", "type": "\\Game" },
  { "label": "\\Gamma", "type": "\\Gamma" },
  { "label": "\\Im", "type": "\\Im" },
  { "label": "\\Lambda", "type": "\\Lambda" },
  { "label": "\\Leftarrow", "type": "\\Leftarrow" },
  { "label": "\\Leftrightarrow", "type": "\\Leftrightarrow" },
  { "label": "\\Lleftarrow", "type": "\\Lleftarrow" },
  { "label": "\\Longleftarrow", "type": "\\Longleftarrow" },
  { "label": "\\Longleftrightarrow", "type": "\\Longleftrightarrow" },
  { "label": "\\Longrightarrow", "type": "\\Longrightarrow" },
  { "label": "\\Lsh", "type": "\\Lsh" },
  { "label": "\\Omega", "type": "\\Omega" },
  { "label": "\\Phi", "type": "\\Phi" },
  { "label": "\\Pi", "type": "\\Pi" },
  { "label": "\\Pr", "type": "\\Pr" },
  { "label": "\\Psi", "type": "\\Psi" },
  { "label": "\\Re", "type": "\\Re" },
  { "label": "\\Rightarrow", "type": "\\Rightarrow" },
  { "label": "\\Rrightarrow", "type": "\\Rrightarrow" },
  { "label": "\\Rsh", "type": "\\Rsh" },
  { "label": "\\S", "type": "\\S" },
  { "label": "\\Sigma", "type": "\\Sigma" },
  { "label": "\\Subset", "type": "\\Subset" },
  { "label": "\\Supset", "type": "\\Supset" },
  { "label": "\\TeX", "type": "\\TeX" },
  { "label": "\\Theta", "type": "\\Theta" },
  { "label": "\\Uparrow", "type": "\\Uparrow" },
  { "label": "\\Updownarrow", "type": "\\Updownarrow" },
  { "label": "\\Upsilon", "type": "\\Upsilon" },
  { "label": "\\Vdash", "type": "\\Vdash" },
  { "label": "\\Vert", "type": "\\Vert" },
  { "label": "\\Vvdash", "type": "\\Vvdash" },
  { "label": "\\Xi", "type": "\\Xi" },
  { "label": "\\above", "type": "\\above" },
  { "label": "\\abovewithdelims", "type": "\\abovewithdelims" },
  { "label": "\\acute{#1}", "type": "\\acute{#1}" },
  { "label": "\\aleph", "type": "\\aleph" },
  { "label": "\\alpha", "type": "\\alpha" },
  { "label": "\\amalg", "type": "\\amalg" },
  { "label": "\\angle", "type": "\\angle" },
  { "label": "\\approx", "type": "\\approx" },
  { "label": "\\approxeq", "type": "\\approxeq" },
  { "label": "\\arccos", "type": "\\arccos" },
  { "label": "\\arcsin", "type": "\\arcsin" },
  { "label": "\\arctan", "type": "\\arctan" },
  { "label": "\\arg", "type": "\\arg" },
  { "label": "\\arrowvert", "type": "\\arrowvert" },
  { "label": "\\ast", "type": "\\ast" },
  { "label": "\\asymp", "type": "\\asymp" },
  { "label": "\\atop", "type": "\\atop" },
  { "label": "\\atopwithdelims", "type": "\\atopwithdelims" },
  { "label": "\\backepsilon", "type": "\\backepsilon" },
  { "label": "\\backprime", "type": "\\backprime" },
  { "label": "\\backsim", "type": "\\backsim" },
  { "label": "\\backsimeq", "type": "\\backsimeq" },
  { "label": "\\backslash", "type": "\\backslash" },
  { "label": "\\bar{#1}", "type": "\\bar{#1}" },
  { "label": "\\barwedge", "type": "\\barwedge" },
  { "label": "\\because", "type": "\\because" },
  { "label": "\\beta", "type": "\\beta" },
  { "label": "\\beth", "type": "\\beth" },
  { "label": "\\between", "type": "\\between" },
  { "label": "\\bf", "type": "\\bf" },
  { "label": "\\big", "type": "\\big" },
  { "label": "\\bigcap", "type": "\\bigcap" },
  { "label": "\\bigcirc", "type": "\\bigcirc" },
  { "label": "\\bigcup", "type": "\\bigcup" },
  { "label": "\\bigg", "type": "\\bigg" },
  { "label": "\\biggl", "type": "\\biggl" },
  { "label": "\\biggm", "type": "\\biggm" },
  { "label": "\\biggr", "type": "\\biggr" },
  { "label": "\\bigl", "type": "\\bigl" },
  { "label": "\\bigm", "type": "\\bigm" },
  { "label": "\\bigodot", "type": "\\bigodot" },
  { "label": "\\bigoplus", "type": "\\bigoplus" },
  { "label": "\\bigotimes", "type": "\\bigotimes" },
  { "label": "\\bigr\\}", "type": "\\bigr\\}" },
  { "label": "\\bigsqcup", "type": "\\bigsqcup" },
  { "label": "\\bigstar", "type": "\\bigstar" },
  { "label": "\\bigtriangledown", "type": "\\bigtriangledown" },
  { "label": "\\bigtriangleup", "type": "\\bigtriangleup" },
  { "label": "\\biguplus", "type": "\\biguplus" },
  { "label": "\\bigvee", "type": "\\bigvee" },
  { "label": "\\bigwedge", "type": "\\bigwedge" },
  { "label": "\\binom{#1}{#2}", "type": "\\binom{#1}{#2}" },
  { "label": "\\blacklozenge", "type": "\\blacklozenge" },
  { "label": "\\blacksquare", "type": "\\blacksquare" },
  { "label": "\\blacktriangle", "type": "\\blacktriangle" },
  { "label": "\\blacktriangledown", "type": "\\blacktriangledown" },
  { "label": "\\blacktriangleleft", "type": "\\blacktriangleleft" },
  { "label": "\\blacktriangleright", "type": "\\blacktriangleright" },
  { "label": "\\bmod", "type": "\\bmod" },
  { "label": "\\boldsymbol{#1}", "type": "\\boldsymbol{#1}" },
  { "label": "\\bot", "type": "\\bot" },
  { "label": "\\bowtie", "type": "\\bowtie" },
  { "label": "\\boxdot", "type": "\\boxdot" },
  { "label": "\\boxed{#1}", "type": "\\boxed{#1}" },
  { "label": "\\boxminus", "type": "\\boxminus" },
  { "label": "\\boxplus", "type": "\\boxplus" },
  { "label": "\\boxtimes", "type": "\\boxtimes" },
  { "label": "\\brace", "type": "\\brace" },
  { "label": "\\bracevert", "type": "\\bracevert" },
  { "label": "\\brack", "type": "\\brack" },
  { "label": "\\breve{#1}", "type": "\\breve{#1}" },
  { "label": "\\buildrel", "type": "\\buildrel" },
  { "label": "\\bullet", "type": "\\bullet" },
  { "label": "\\bumpeq", "type": "\\bumpeq" },
  { "label": "\\cal", "type": "\\cal" },
  { "label": "\\cap", "type": "\\cap" },
  { "label": "\\cases{#1}", "type": "\\cases{#1}" },
  { "label": "\\cdot", "type": "\\cdot" },
  { "label": "\\cdotp", "type": "\\cdotp" },
  { "label": "\\cdots", "type": "\\cdots" },
  { "label": "\\centerdot", "type": "\\centerdot" },
  { "label": "\\cfrac{#1}{#2}", "type": "\\cfrac{#1}{#2}" },
  { "label": "\\check{#1}", "type": "\\check{#1}" },
  { "label": "\\checkmark", "type": "\\checkmark" },
  { "label": "\\chi", "type": "\\chi" },
  { "label": "\\choose", "type": "\\choose" },
  { "label": "\\circ", "type": "\\circ" },
  { "label": "\\circeq", "type": "\\circeq" },
  { "label": "\\circlearrowleft", "type": "\\circlearrowleft" },
  { "label": "\\circlearrowright", "type": "\\circlearrowright" },
  { "label": "\\circledS", "type": "\\circledS" },
  { "label": "\\circledast", "type": "\\circledast" },
  { "label": "\\circledcirc", "type": "\\circledcirc" },
  { "label": "\\circleddash", "type": "\\circleddash" },
  { "label": "\\clubsuit", "type": "\\clubsuit" },
  { "label": "\\colon", "type": "\\colon" },
  { "label": "\\complement", "type": "\\complement" },
  { "label": "\\cong", "type": "\\cong" },
  { "label": "\\coprod", "type": "\\coprod" },
  { "label": "\\cos", "type": "\\cos" },
  { "label": "\\cosh", "type": "\\cosh" },
  { "label": "\\cot", "type": "\\cot" },
  { "label": "\\coth", "type": "\\coth" },
  { "label": "\\cr", "type": "\\cr" },
  { "label": "\\csc", "type": "\\csc" },
  { "label": "\\cup", "type": "\\cup" },
  { "label": "\\curlyeqprec", "type": "\\curlyeqprec" },
  { "label": "\\curlyeqsucc", "type": "\\curlyeqsucc" },
  { "label": "\\curlyvee", "type": "\\curlyvee" },
  { "label": "\\curlywedge", "type": "\\curlywedge" },
  { "label": "\\curvearrowleft", "type": "\\curvearrowleft" },
  { "label": "\\curvearrowright", "type": "\\curvearrowright" },
  { "label": "\\dagger", "type": "\\dagger" },
  { "label": "\\daleth", "type": "\\daleth" },
  { "label": "\\dashleftarrow", "type": "\\dashleftarrow" },
  { "label": "\\dashrightarrow", "type": "\\dashrightarrow" },
  { "label": "\\dashv", "type": "\\dashv" },
  { "label": "\\dbinom{#1}{#2}", "type": "\\dbinom{#1}{#2}" },
  { "label": "\\ddagger", "type": "\\ddagger" },
  { "label": "\\ddddot{#1}", "type": "\\ddddot{#1}" },
  { "label": "\\dddot{#1}", "type": "\\dddot{#1}" },
  { "label": "\\ddot{#1}", "type": "\\ddot{#1}" },
  { "label": "\\ddots", "type": "\\ddots" },
  { "label": "\\def", "type": "\\def" },
  { "label": "\\deg", "type": "\\deg" },
  { "label": "\\delta", "type": "\\delta" },
  { "label": "\\det", "type": "\\det" },
  { "label": "\\dfrac{#1}{#2}", "type": "\\dfrac{#1}{#2}" },
  { "label": "\\diagdown", "type": "\\diagdown" },
  { "label": "\\diagup", "type": "\\diagup" },
  { "label": "\\diamond", "type": "\\diamond" },
  { "label": "\\diamondsuit", "type": "\\diamondsuit" },
  { "label": "\\digamma", "type": "\\digamma" },
  { "label": "\\dim", "type": "\\dim" },
  { "label": "\\displaylines", "type": "\\displaylines" },
  { "label": "\\displaystyle", "type": "\\displaystyle" },
  { "label": "\\div", "type": "\\div" },
  { "label": "\\divideontimes", "type": "\\divideontimes" },
  { "label": "\\dot{#1}", "type": "\\dot{#1}" },
  { "label": "\\doteq", "type": "\\doteq" },
  { "label": "\\doteqdot", "type": "\\doteqdot" },
  { "label": "\\dotplus", "type": "\\dotplus" },
  { "label": "\\dots", "type": "\\dots" },
  { "label": "\\dotsb", "type": "\\dotsb" },
  { "label": "\\dotsc", "type": "\\dotsc" },
  { "label": "\\dotsi", "type": "\\dotsi" },
  { "label": "\\dotsm", "type": "\\dotsm" },
  { "label": "\\dotso", "type": "\\dotso" },
  { "label": "\\doublebarwedge", "type": "\\doublebarwedge" },
  { "label": "\\downarrow", "type": "\\downarrow" },
  { "label": "\\downdownarrows", "type": "\\downdownarrows" },
  { "label": "\\downharpoonleft", "type": "\\downharpoonleft" },
  { "label": "\\downharpoonright", "type": "\\downharpoonright" },
  { "label": "\\ell", "type": "\\ell" },
  { "label": "\\emptyset", "type": "\\emptyset" },
  { "label": "\\enspace", "type": "\\enspace" },
  { "label": "\\epsilon", "type": "\\epsilon" },
  { "label": "\\eqalign{#1}", "type": "\\eqalign{#1}" },
  { "label": "\\eqalignno{#1}", "type": "\\eqalignno{#1}" },
  { "label": "\\eqcirc", "type": "\\eqcirc" },
  { "label": "\\eqref{#1}", "type": "\\eqref{#1}" },
  { "label": "\\eqsim", "type": "\\eqsim" },
  { "label": "\\eqslantgtr", "type": "\\eqslantgtr" },
  { "label": "\\eqslantless", "type": "\\eqslantless" },
  { "label": "\\equiv", "type": "\\equiv" },
  { "label": "\\eta", "type": "\\eta" },
  { "label": "\\eth", "type": "\\eth" },
  { "label": "\\exists", "type": "\\exists" },
  { "label": "\\exp", "type": "\\exp" },
  { "label": "\\fallingdotseq", "type": "\\fallingdotseq" },
  { "label": "\\flat", "type": "\\flat" },
  { "label": "\\forall", "type": "\\forall" },
  { "label": "\\frown", "type": "\\frown" },
  { "label": "\\gamma", "type": "\\gamma" },
  { "label": "\\gcd", "type": "\\gcd" },
  { "label": "\\ge", "type": "\\ge" },
  { "label": "\\geq", "type": "\\geq" },
  { "label": "\\geqq", "type": "\\geqq" },
  { "label": "\\geqslant", "type": "\\geqslant" },
  { "label": "\\gets", "type": "\\gets" },
  { "label": "\\gg", "type": "\\gg" },
  { "label": "\\ggg", "type": "\\ggg" },
  { "label": "\\gimel", "type": "\\gimel" },
  { "label": "\\gnapprox", "type": "\\gnapprox" },
  { "label": "\\gneq", "type": "\\gneq" },
  { "label": "\\gneqq", "type": "\\gneqq" },
  { "label": "\\gnsim", "type": "\\gnsim" },
  { "label": "\\grave{#1}", "type": "\\grave{#1}" },
  { "label": "\\gtrapprox", "type": "\\gtrapprox" },
  { "label": "\\gtrdot", "type": "\\gtrdot" },
  { "label": "\\gtreqless", "type": "\\gtreqless" },
  { "label": "\\gtreqqless", "type": "\\gtreqqless" },
  { "label": "\\gtrless", "type": "\\gtrless" },
  { "label": "\\gtrsim", "type": "\\gtrsim" },
  { "label": "\\gvertneqq", "type": "\\gvertneqq" },
  { "label": "\\hat{#1}", "type": "\\hat{#1}" },
  { "label": "\\hbar", "type": "\\hbar" },
  { "label": "\\hbox", "type": "\\hbox" },
  { "label": "\\heartsuit", "type": "\\heartsuit" },
  { "label": "\\hfil", "type": "\\hfil" },
  { "label": "\\hfill", "type": "\\hfill" },
  { "label": "\\hom", "type": "\\hom" },
  { "label": "\\hookleftarrow", "type": "\\hookleftarrow" },
  { "label": "\\hookrightarrow", "type": "\\hookrightarrow" },
  { "label": "\\hphantom{#1}", "type": "\\hphantom{#1}" },
  { "label": "\\hskip", "type": "\\hskip" },
  { "label": "\\hslash", "type": "\\hslash" },
  { "label": "\\idotsint", "type": "\\idotsint" },
  { "label": "\\iff", "type": "\\iff" },
  { "label": "\\iiiint", "type": "\\iiiint" },
  { "label": "\\iiint", "type": "\\iiint" },
  { "label": "\\iint", "type": "\\iint" },
  { "label": "\\imath", "type": "\\imath" },
  { "label": "\\impliedby", "type": "\\impliedby" },
  { "label": "\\implies", "type": "\\implies" },
  { "label": "\\in", "type": "\\in" },
  { "label": "\\inf", "type": "\\inf" },
  { "label": "\\infty", "type": "\\infty" },
  { "label": "\\injlim", "type": "\\injlim" },
  { "label": "\\int\\limits_{#1}^{#2}", "type": "\\int\\limits_{#1}^{#2}" },
  { "label": "\\intercal", "type": "\\intercal" },
  { "label": "\\iota", "type": "\\iota" },
  { "label": "\\it", "type": "\\it" },
  { "label": "\\jmath", "type": "\\jmath" },
  { "label": "\\kappa", "type": "\\kappa" },
  { "label": "\\ker", "type": "\\ker" },
  { "label": "\\kern", "type": "\\kern" },
  { "label": "\\lVert", "type": "\\lVert" },
  { "label": "\\lambda", "type": "\\lambda" },
  { "label": "\\land", "type": "\\land" },
  { "label": "\\langle", "type": "\\langle" },
  { "label": "\\lbrace", "type": "\\lbrace" },
  { "label": "\\lbrack", "type": "\\lbrack" },
  { "label": "\\lceil", "type": "\\lceil" },
  { "label": "\\ldotp", "type": "\\ldotp" },
  { "label": "\\ldots", "type": "\\ldots" },
  { "label": "\\le", "type": "\\le" },
  { "label": "\\left", "type": "\\left" },
  { "label": "\\leftarrow", "type": "\\leftarrow" },
  { "label": "\\leftarrowtail", "type": "\\leftarrowtail" },
  { "label": "\\leftharpoondown", "type": "\\leftharpoondown" },
  { "label": "\\leftharpoonup", "type": "\\leftharpoonup" },
  { "label": "\\leftleftarrows", "type": "\\leftleftarrows" },
  { "label": "\\leftrightarrow", "type": "\\leftrightarrow" },
  { "label": "\\leftrightarrows", "type": "\\leftrightarrows" },
  { "label": "\\leftrightharpoons", "type": "\\leftrightharpoons" },
  { "label": "\\leftrightsquigarrow", "type": "\\leftrightsquigarrow" },
  { "label": "\\leftroot{#1}", "type": "\\leftroot{#1}" },
  { "label": "\\leftthreetimes", "type": "\\leftthreetimes" },
  { "label": "\\leq", "type": "\\leq" },
  { "label": "\\leqalignno{#1}", "type": "\\leqalignno{#1}" },
  { "label": "\\leqq", "type": "\\leqq" },
  { "label": "\\leqslant", "type": "\\leqslant" },
  { "label": "\\lessapprox", "type": "\\lessapprox" },
  { "label": "\\lessdot", "type": "\\lessdot" },
  { "label": "\\lesseqgtr", "type": "\\lesseqgtr" },
  { "label": "\\lesseqqgtr", "type": "\\lesseqqgtr" },
  { "label": "\\lessgtr", "type": "\\lessgtr" },
  { "label": "\\lesssim", "type": "\\lesssim" },
  { "label": "\\let{#1}{#2}", "type": "\\let{#1}{#2}" },
  { "label": "\\lfloor", "type": "\\lfloor" },
  { "label": "\\lg", "type": "\\lg" },
  { "label": "\\lgroup", "type": "\\lgroup" },
  { "label": "\\lhd", "type": "\\lhd" },
  { "label": "\\lim", "type": "\\lim" },
  { "label": "\\liminf", "type": "\\liminf" },
  { "label": "\\limits_{#1}^{#b}", "type": "\\limits_{#1}^{#b}" },
  { "label": "\\limsup", "type": "\\limsup" },
  { "label": "\\ll", "type": "\\ll" },
  { "label": "\\llap{#1}", "type": "\\llap{#1}" },
  { "label": "\\llcorner", "type": "\\llcorner" },
  { "label": "\\lll", "type": "\\lll" },
  { "label": "\\lmoustache", "type": "\\lmoustache" },
  { "label": "\\ln", "type": "\\ln" },
  { "label": "\\lnapprox", "type": "\\lnapprox" },
  { "label": "\\lneq", "type": "\\lneq" },
  { "label": "\\lneqq", "type": "\\lneqq" },
  { "label": "\\lnot", "type": "\\lnot" },
  { "label": "\\lnsim", "type": "\\lnsim" },
  { "label": "\\log", "type": "\\log" },
  { "label": "\\longleftarrow", "type": "\\longleftarrow" },
  { "label": "\\longleftrightarrow", "type": "\\longleftrightarrow" },
  { "label": "\\longmapsto", "type": "\\longmapsto" },
  { "label": "\\longrightarrow", "type": "\\longrightarrow" },
  { "label": "\\looparrowleft", "type": "\\looparrowleft" },
  { "label": "\\looparrowright", "type": "\\looparrowright" },
  { "label": "\\lor", "type": "\\lor" },
  { "label": "\\lower", "type": "\\lower" },
  { "label": "\\lozenge", "type": "\\lozenge" },
  { "label": "\\lrcorner", "type": "\\lrcorner" },
  { "label": "\\ltimes", "type": "\\ltimes" },
  { "label": "\\lvert", "type": "\\lvert" },
  { "label": "\\lvertneqq", "type": "\\lvertneqq" },
  { "label": "\\maltese", "type": "\\maltese" },
  { "label": "\\mapsto", "type": "\\mapsto" },
  { "label": "\\mathbb{#1}", "type": "\\mathbb{#1}" },
  { "label": "\\mathbf{#1}", "type": "\\mathbf{#1}" },
  { "label": "\\mathbin", "type": "\\mathbin" },
  { "label": "\\mathchoice", "type": "\\mathchoice" },
  { "label": "\\mathclose", "type": "\\mathclose" },
  { "label": "\\mathfrak{#1}", "type": "\\mathfrak{#1}" },
  { "label": "\\mathinner", "type": "\\mathinner" },
  { "label": "\\mathop", "type": "\\mathop" },
  { "label": "\\mathopen", "type": "\\mathopen" },
  { "label": "\\mathord", "type": "\\mathord" },
  { "label": "\\mathpunct", "type": "\\mathpunct" },
  { "label": "\\mathrel", "type": "\\mathrel" },
  { "label": "\\mathstrut", "type": "\\mathstrut" },
  { "label": "\\matrix{#1}", "type": "\\matrix{#1}" },
  { "label": "\\max", "type": "\\max" },
  { "label": "\\measuredangle", "type": "\\measuredangle" },
  { "label": "\\mho", "type": "\\mho" },
  { "label": "\\mid", "type": "\\mid" },
  { "label": "\\middle", "type": "\\middle" },
  { "label": "\\min", "type": "\\min" },
  { "label": "\\mit", "type": "\\mit" },
  { "label": "\\mkern", "type": "\\mkern" },
  { "label": "\\mod", "type": "\\mod" },
  { "label": "\\models", "type": "\\models" },
  { "label": "\\moveleft", "type": "\\moveleft" },
  { "label": "\\moveright", "type": "\\moveright" },
  { "label": "\\mp", "type": "\\mp" },
  { "label": "\\mskip", "type": "\\mskip" },
  { "label": "\\mspace{#1}", "type": "\\mspace{#1}" },
  { "label": "\\mu", "type": "\\mu" },
  { "label": "\\multimap", "type": "\\multimap" },
  { "label": "\\nLeftarrow", "type": "\\nLeftarrow" },
  { "label": "\\nLeftrightarrow", "type": "\\nLeftrightarrow" },
  { "label": "\\nRightarrow", "type": "\\nRightarrow" },
  { "label": "\\nVDash", "type": "\\nVDash" },
  { "label": "\\nVdash", "type": "\\nVdash" },
  { "label": "\\nabla", "type": "\\nabla" },
  { "label": "\\natural", "type": "\\natural" },
  { "label": "\\ncong", "type": "\\ncong" },
  { "label": "\\ne", "type": "\\ne" },
  { "label": "\\nearrow", "type": "\\nearrow" },
  { "label": "\\neg", "type": "\\neg" },
  { "label": "\\negmedspace", "type": "\\negmedspace" },
  { "label": "\\negthickspace", "type": "\\negthickspace" },
  { "label": "\\negthinspace", "type": "\\negthinspace" },
  { "label": "\\neq", "type": "\\neq" },
  { "label": "\\nexists", "type": "\\nexists" },
  { "label": "\\ngeq", "type": "\\ngeq" },
  { "label": "\\ngeqq", "type": "\\ngeqq" },
  { "label": "\\ngeqslant", "type": "\\ngeqslant" },
  { "label": "\\ngtr", "type": "\\ngtr" },
  { "label": "\\ni", "type": "\\ni" },
  { "label": "\\nleftarrow", "type": "\\nleftarrow" },
  { "label": "\\nleftrightarrow", "type": "\\nleftrightarrow" },
  { "label": "\\nleq", "type": "\\nleq" },
  { "label": "\\nleqq", "type": "\\nleqq" },
  { "label": "\\nleqslant", "type": "\\nleqslant" },
  { "label": "\\nless", "type": "\\nless" },
  { "label": "\\nmid", "type": "\\nmid" },
  { "label": "\\nolimits_{#1}^{#2}", "type": "\\nolimits_{#1}^{#2}" },
  { "label": "\\not", "type": "\\not" },
  { "label": "\\notag", "type": "\\notag" },
  { "label": "\\notin", "type": "\\notin" },
  { "label": "\\nparallel", "type": "\\nparallel" },
  { "label": "\\nprec", "type": "\\nprec" },
  { "label": "\\npreceq", "type": "\\npreceq" },
  { "label": "\\nrightarrow", "type": "\\nrightarrow" },
  { "label": "\\nshortmid", "type": "\\nshortmid" },
  { "label": "\\nshortparallel", "type": "\\nshortparallel" },
  { "label": "\\nsim", "type": "\\nsim" },
  { "label": "\\nsubseteq", "type": "\\nsubseteq" },
  { "label": "\\nsubseteqq", "type": "\\nsubseteqq" },
  { "label": "\\nsucc", "type": "\\nsucc" },
  { "label": "\\nsucceq", "type": "\\nsucceq" },
  { "label": "\\nsupseteq", "type": "\\nsupseteq" },
  { "label": "\\nsupseteqq", "type": "\\nsupseteqq" },
  { "label": "\\ntriangleleft", "type": "\\ntriangleleft" },
  { "label": "\\ntrianglelefteq", "type": "\\ntrianglelefteq" },
  { "label": "\\ntriangleright", "type": "\\ntriangleright" },
  { "label": "\\ntrianglerighteq", "type": "\\ntrianglerighteq" },
  { "label": "\\nu", "type": "\\nu" },
  { "label": "\\nvDash", "type": "\\nvDash" },
  { "label": "\\nvdash", "type": "\\nvdash" },
  { "label": "\\nwarrow", "type": "\\nwarrow" },
  { "label": "\\odot", "type": "\\odot" },
  { "label": "\\oint", "type": "\\oint" },
  { "label": "\\oldstyle", "type": "\\oldstyle" },
  { "label": "\\omega", "type": "\\omega" },
  { "label": "\\ominus", "type": "\\ominus" },
  { "label": "\\operatorname{#1}", "type": "\\operatorname{#1}" },
  { "label": "\\oplus", "type": "\\oplus" },
  { "label": "\\oslash", "type": "\\oslash" },
  { "label": "\\otimes", "type": "\\otimes" },
  { "label": "\\over", "type": "\\over" },
  { "label": "\\overbrace{#1}", "type": "\\overbrace{#1}" },
  { "label": "\\overleftarrow{#1}", "type": "\\overleftarrow{#1}" },
  { "label": "\\overleftrightarrow{#1}", "type": "\\overleftrightarrow{#1}" },
  { "label": "\\overline{#1}", "type": "\\overline{#1}" },
  { "label": "\\overrightarrow{#1}", "type": "\\overrightarrow{#1}" },
  { "label": "\\overset{#1}{#2}", "type": "\\overset{#1}{#2}" },
  { "label": "\\overwithdelims", "type": "\\overwithdelims" },
  { "label": "\\owns", "type": "\\owns" },
  { "label": "\\parallel", "type": "\\parallel" },
  { "label": "\\partial", "type": "\\partial" },
  { "label": "\\perp", "type": "\\perp" },
  { "label": "\\phantom{#1}", "type": "\\phantom{#1}" },
  { "label": "\\phi", "type": "\\phi" },
  { "label": "\\pi", "type": "\\pi" },
  { "label": "\\pitchfork", "type": "\\pitchfork" },
  { "label": "\\pm", "type": "\\pm" },
  { "label": "\\pmatrix{#1}", "type": "\\pmatrix{#1}" },
  { "label": "\\pmb{#1}", "type": "\\pmb{#1}" },
  { "label": "\\pmod", "type": "\\pmod" },
  { "label": "\\pod", "type": "\\pod" },
  { "label": "\\prec", "type": "\\prec" },
  { "label": "\\precapprox", "type": "\\precapprox" },
  { "label": "\\preccurlyeq", "type": "\\preccurlyeq" },
  { "label": "\\preceq", "type": "\\preceq" },
  { "label": "\\precnapprox", "type": "\\precnapprox" },
  { "label": "\\precneqq", "type": "\\precneqq" },
  { "label": "\\precnsim", "type": "\\precnsim" },
  { "label": "\\precsim", "type": "\\precsim" },
  { "label": "\\prime", "type": "\\prime" },
  { "label": "\\prod\\limits_{#1}^{#2}", "type": "\\prod\\limits_{#1}^{#2}" },
  { "label": "\\projlim", "type": "\\projlim" },
  { "label": "\\propto", "type": "\\propto" },
  { "label": "\\psi", "type": "\\psi" },
  { "label": "\\qquad", "type": "\\qquad" },
  { "label": "\\quad", "type": "\\quad" },
  { "label": "\\rVert", "type": "\\rVert" },
  { "label": "\\raise", "type": "\\raise" },
  { "label": "\\rangle", "type": "\\rangle" },
  { "label": "\\rbrace", "type": "\\rbrace" },
  { "label": "\\rbrack", "type": "\\rbrack" },
  { "label": "\\rceil", "type": "\\rceil" },
  { "label": "\\rfloor", "type": "\\rfloor" },
  { "label": "\\rgroup", "type": "\\rgroup" },
  { "label": "\\rhd", "type": "\\rhd" },
  { "label": "\\rho", "type": "\\rho" },
  { "label": "\\right", "type": "\\right" },
  { "label": "\\rightarrow", "type": "\\rightarrow" },
  { "label": "\\rightarrowtail", "type": "\\rightarrowtail" },
  { "label": "\\rightharpoondown", "type": "\\rightharpoondown" },
  { "label": "\\rightharpoonup", "type": "\\rightharpoonup" },
  { "label": "\\rightleftarrows", "type": "\\rightleftarrows" },
  { "label": "\\rightleftharpoons", "type": "\\rightleftharpoons" },
  { "label": "\\rightrightarrows", "type": "\\rightrightarrows" },
  { "label": "\\rightsquigarrow", "type": "\\rightsquigarrow" },
  { "label": "\\rightthreetimes", "type": "\\rightthreetimes" },
  { "label": "\\risingdotseq", "type": "\\risingdotseq" },
  { "label": "\\rlap{#1}", "type": "\\rlap{#1}" },
  { "label": "\\rm", "type": "\\rm" },
  { "label": "\\rmoustache", "type": "\\rmoustache" },
  { "label": "\\root #1 \\of #2", "type": "\\root #1 \\of #2" },
  { "label": "\\rtimes", "type": "\\rtimes" },
  { "label": "\\rvert", "type": "\\rvert" },
  { "label": "\\scriptscriptstyle", "type": "\\scriptscriptstyle" },
  { "label": "\\scriptstyle", "type": "\\scriptstyle" },
  { "label": "\\searrow", "type": "\\searrow" },
  { "label": "\\sec", "type": "\\sec" },
  { "label": "\\setminus", "type": "\\setminus" },
  { "label": "\\sharp", "type": "\\sharp" },
  { "label": "\\shortmid", "type": "\\shortmid" },
  { "label": "\\shortparallel", "type": "\\shortparallel" },
  { "label": "\\sideset{#1}{#2}{#3}", "type": "\\sideset{#1}{#2}{#3}" },
  { "label": "\\sigma", "type": "\\sigma" },
  { "label": "\\sim", "type": "\\sim" },
  { "label": "\\simeq", "type": "\\simeq" },
  { "label": "\\sin", "type": "\\sin" },
  { "label": "\\sinh", "type": "\\sinh" },
  { "label": "\\skew{#1}{#2}{#3}", "type": "\\skew{#1}{#2}{#3}" },
  { "label": "\\smallfrown", "type": "\\smallfrown" },
  { "label": "\\smallint", "type": "\\smallint" },
  { "label": "\\smallsetminus", "type": "\\smallsetminus" },
  { "label": "\\smallsmile", "type": "\\smallsmile" },
  { "label": "\\smash{#1}", "type": "\\smash{#1}" },
  { "label": "\\smile", "type": "\\smile" },
  { "label": "\\space", "type": "\\space" },
  { "label": "\\spadesuit", "type": "\\spadesuit" },
  { "label": "\\sphericalangle", "type": "\\sphericalangle" },
  { "label": "\\sqcap", "type": "\\sqcap" },
  { "label": "\\sqcup", "type": "\\sqcup" },
  { "label": "\\sqrt{#1}", "type": "\\sqrt{#1}" },
  { "label": "\\sqsubset", "type": "\\sqsubset" },
  { "label": "\\sqsubseteq", "type": "\\sqsubseteq" },
  { "label": "\\sqsupset", "type": "\\sqsupset" },
  { "label": "\\sqsupseteq", "type": "\\sqsupseteq" },
  { "label": "\\square", "type": "\\square" },
  { "label": "\\star", "type": "\\star" },
  { "label": "\\strut", "type": "\\strut" },
  { "label": "\\subset", "type": "\\subset" },
  { "label": "\\subseteq", "type": "\\subseteq" },
  { "label": "\\subseteqq", "type": "\\subseteqq" },
  { "label": "\\subsetneq", "type": "\\subsetneq" },
  { "label": "\\subsetneqq", "type": "\\subsetneqq" },
  { "label": "\\substack{#1}", "type": "\\substack{#1}" },
  { "label": "\\succ", "type": "\\succ" },
  { "label": "\\succapprox", "type": "\\succapprox" },
  { "label": "\\succcurlyeq", "type": "\\succcurlyeq" },
  { "label": "\\succeq", "type": "\\succeq" },
  { "label": "\\succnapprox", "type": "\\succnapprox" },
  { "label": "\\succneqq", "type": "\\succneqq" },
  { "label": "\\succnsim", "type": "\\succnsim" },
  { "label": "\\succsim", "type": "\\succsim" },
  { "label": "\\sum\\limits_{#1}^{#2}", "type": "\\sum\\limits_{#1}^{#2}" },
  { "label": "\\sup", "type": "\\sup" },
  { "label": "\\supset", "type": "\\supset" },
  { "label": "\\supseteq", "type": "\\supseteq" },
  { "label": "\\supseteqq", "type": "\\supseteqq" },
  { "label": "\\supsetneq", "type": "\\supsetneq" },
  { "label": "\\supsetneqq", "type": "\\supsetneqq" },
  { "label": "\\surd", "type": "\\surd" },
  { "label": "\\swarrow", "type": "\\swarrow" },
  { "label": "\\tag{#1}", "type": "\\tag{#1}" },
  { "label": "\\tan", "type": "\\tan" },
  { "label": "\\tanh", "type": "\\tanh" },
  { "label": "\\tau", "type": "\\tau" },
  { "label": "\\tbinom{#1}{#2}", "type": "\\tbinom{#1}{#2}" },
  { "label": "\\label{#1}", "type": "\\label{#1}" },
  { "label": "\\textstyle", "type": "\\textstyle" },
  { "label": "\\tfrac{#1}{#2}", "type": "\\tfrac{#1}{#2}" },
  { "label": "\\therefore", "type": "\\therefore" },
  { "label": "\\theta", "type": "\\theta" },
  { "label": "\\thickapprox", "type": "\\thickapprox" },
  { "label": "\\thicksim", "type": "\\thicksim" },
  { "label": "\\thinspace", "type": "\\thinspace" },
  { "label": "\\tilde{#1}", "type": "\\tilde{#1}" },
  { "label": "\\times", "type": "\\times" },
  { "label": "\\to", "type": "\\to" },
  { "label": "\\top", "type": "\\top" },
  { "label": "\\triangle", "type": "\\triangle" },
  { "label": "\\triangledown", "type": "\\triangledown" },
  { "label": "\\triangleleft", "type": "\\triangleleft" },
  { "label": "\\trianglelefteq", "type": "\\trianglelefteq" },
  { "label": "\\triangleq", "type": "\\triangleq" },
  { "label": "\\triangleright", "type": "\\triangleright" },
  { "label": "\\trianglerighteq", "type": "\\trianglerighteq" },
  { "label": "\\tt", "type": "\\tt" },
  { "label": "\\twoheadleftarrow", "type": "\\twoheadleftarrow" },
  { "label": "\\twoheadrightarrow", "type": "\\twoheadrightarrow" },
  { "label": "\\ulcorner", "type": "\\ulcorner" },
  { "label": "\\underbrace{#1}", "type": "\\underbrace{#1}" },
  { "label": "\\underleftarrow{#1}", "type": "\\underleftarrow{#1}" },
  { "label": "\\underleftrightarrow{#1}", "type": "\\underleftrightarrow{#1}" },
  { "label": "\\underline{#1}", "type": "\\underline{#1}" },
  { "label": "\\underrightarrow{#1}", "type": "\\underrightarrow{#1}" },
  { "label": "\\underset{#1}{#2}", "type": "\\underset{#1}{#2}" },
  { "label": "\\unlhd", "type": "\\unlhd" },
  { "label": "\\unrhd", "type": "\\unrhd" },
  { "label": "\\uparrow", "type": "\\uparrow" },
  { "label": "\\updownarrow", "type": "\\updownarrow" },
  { "label": "\\upharpoonleft", "type": "\\upharpoonleft" },
  { "label": "\\upharpoonright", "type": "\\upharpoonright" },
  { "label": "\\uplus", "type": "\\uplus" },
  { "label": "\\uproot{#1}", "type": "\\uproot{#1}" },
  { "label": "\\upsilon", "type": "\\upsilon" },
  { "label": "\\upuparrows", "type": "\\upuparrows" },
  { "label": "\\urcorner", "type": "\\urcorner" },
  { "label": "\\vDash", "type": "\\vDash" },
  { "label": "\\varDelta", "type": "\\varDelta" },
  { "label": "\\varGamma", "type": "\\varGamma" },
  { "label": "\\varLambda", "type": "\\varLambda" },
  { "label": "\\varOmega", "type": "\\varOmega" },
  { "label": "\\varPhi", "type": "\\varPhi" },
  { "label": "\\varPi", "type": "\\varPi" },
  { "label": "\\varPsi", "type": "\\varPsi" },
  { "label": "\\varSigma", "type": "\\varSigma" },
  { "label": "\\varTheta", "type": "\\varTheta" },
  { "label": "\\varUpsilon", "type": "\\varUpsilon" },
  { "label": "\\varXi", "type": "\\varXi" },
  { "label": "\\varepsilon", "type": "\\varepsilon" },
  { "label": "\\varinjlim", "type": "\\varinjlim" },
  { "label": "\\varkappa", "type": "\\varkappa" },
  { "label": "\\varliminf", "type": "\\varliminf" },
  { "label": "\\varlimsup", "type": "\\varlimsup" },
  { "label": "\\varnothing", "type": "\\varnothing" },
  { "label": "\\varphi", "type": "\\varphi" },
  { "label": "\\varpi", "type": "\\varpi" },
  { "label": "\\varprojlim", "type": "\\varprojlim" },
  { "label": "\\varpropto", "type": "\\varpropto" },
  { "label": "\\varrho", "type": "\\varrho" },
  { "label": "\\varsigma", "type": "\\varsigma" },
  { "label": "\\varsubsetneq", "type": "\\varsubsetneq" },
  { "label": "\\varsubsetneqq", "type": "\\varsubsetneqq" },
  { "label": "\\varsupsetneq", "type": "\\varsupsetneq" },
  { "label": "\\varsupsetneqq", "type": "\\varsupsetneqq" },
  { "label": "\\vartheta", "type": "\\vartheta" },
  { "label": "\\vartriangle", "type": "\\vartriangle" },
  { "label": "\\vartriangleleft", "type": "\\vartriangleleft" },
  { "label": "\\vartriangleright", "type": "\\vartriangleright" },
  { "label": "\\vcenter", "type": "\\vcenter" },
  { "label": "\\vdash", "type": "\\vdash" },
  { "label": "\\vec{#1}", "type": "\\vec{#1}" },
  { "label": "\\vee", "type": "\\vee" },
  { "label": "\\veebar", "type": "\\veebar" },
  { "label": "\\vert", "type": "\\vert" },
  { "label": "\\vphantom{#1}", "type": "\\vphantom{#1}" },
  { "label": "\\wedge", "type": "\\wedge" },
  { "label": "\\widehat{#1}", "type": "\\widehat{#1}" },
  { "label": "\\widetilde{#1}", "type": "\\widetilde{#1}" },
  { "label": "\\wp", "type": "\\wp" },
  { "label": "\\wr", "type": "\\wr" },
  { "label": "\\xi", "type": "\\xi" },
  { "label": "\\xleftarrow{#1}", "type": "\\xleftarrow{#1}" },
  { "label": "\\xrightarrow{#1}", "type": "\\xrightarrow{#1}" },
  { "label": "\\zeta", "type": "\\zeta" },
  { "label": "\\begin{definition}", "type": "\\begin{definition}\n#1\n\\end{definition}" },
  { "label": "\\begin{tikzcd}", "type": "\\begin{tikzcd}[#1]\n#2\n\\end{tikzcd}" },
  { "label": "\\begin{tikzpicture}", "type": "\\begin{tikzpicture}[#1]\n#2\n\\end{tikzpicture}" },
  { "label": "\\begin{align}", "type": "\\begin{align}\n#1\n\\end{align}" },
  { "label": "\\begin{align*}", "type": "\\begin{align*}\n#1\n\\end{align*}" },
  { "label": "\\begin{alignat}", "type": "\\begin{alignat}\n#1\n\\end{alignat}" },
  { "label": "\\begin{alignat*}", "type": "\\begin{alignat*}\n#1\n\\end{alignat*}" },
  { "label": "\\begin{aligned}", "type": "\\begin{aligned}\n#1\n\\end{aligned}" },
  { "label": "\\begin{alignedat}", "type": "\\begin{alignedat}\n#1\n\\end{alignedat}" },
  { "label": "\\begin{array}", "type": "\\begin{array}\n#1\n\\end{array}" },
  { "label": "\\begin{Bmatrix}", "type": "\\begin{Bmatrix}\n#1\n\\end{Bmatrix}" },
  { "label": "\\begin{bmatrix}", "type": "\\begin{bmatrix}\n#1\n\\end{bmatrix}" },
  { "label": "\\begin{cases}", "type": "\\begin{cases}\n#1\n\\end{cases}" },
  { "label": "\\begin{CD}", "type": "\\begin{CD}\n#1\n\\end{CD}" },
  { "label": "\\begin{eqnarray}", "type": "\\begin{eqnarray}\n#1\n\\end{eqnarray}" },
  { "label": "\\begin{eqnarray*}", "type": "\\begin{eqnarray*}\n#1\n\\end{eqnarray*}" },
  { "label": "\\begin{equation}", "type": "\\begin{equation}\n#1\n\\end{equation}" },
  { "label": "\\begin{equation*}", "type": "\\begin{equation*}\n#1\n\\end{equation*}" },
  { "label": "\\begin{gather}", "type": "\\begin{gather}\n#1\n\\end{gather}" },
  { "label": "\\begin{gather*}", "type": "\\begin{gather*}\n#1\n\\end{gather*}" },
  { "label": "\\begin{gathered}", "type": "\\begin{gathered}\n#1\n\\end{gathered}" },
  { "label": "\\begin{matrix}", "type": "\\begin{matrix}\n#1\n\\end{matrix}" },
  { "label": "\\begin{multline}", "type": "\\begin{multline}\n#1\n\\end{multline}" },
  { "label": "\\begin{multline*}", "type": "\\begin{multline*}\n#1\n\\end{multline*}" },
  { "label": "\\begin{pmatrix}", "type": "\\begin{pmatrix}\n#1\n\\end{pmatrix}" },
  { "label": "\\begin{smallmatrix}", "type": "\\begin{smallmatrix}\n#1\n\\end{smallmatrix}" },
  { "label": "\\begin{split}", "type": "\\begin{split}\n#1\n\\end{split}" },
  { "label": "\\begin{subarray}", "type": "\\begin{subarray}\n#1\n\\end{subarray}" },
  { "label": "\\begin{Vmatrix}", "type": "\\begin{Vmatrix}\n#1\n\\end{Vmatrix}" },
  { "label": "\\begin{vmatrix}", "type": "\\begin{vmatrix}\n#1\n\\end{vmatrix}" }
]


