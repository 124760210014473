import { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { TranscriptionType } from '../types/job.types';
import { getAllPdfs, getPdfStats, getPdfPage } from '../services/useServicesList';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { EditorView, Decoration, DecorationSet, MatchDecorator, ViewPlugin, ViewUpdate, WidgetType } from "@codemirror/view"
import { preventModifyTargetRanges } from 'codemirror-readonly-ranges'
import { langs } from '@uiw/codemirror-extensions-langs';
import { tokyoNightStorm } from '@uiw/codemirror-theme-tokyo-night-storm';
import { autocompletion } from '@codemirror/autocomplete';
import { BEGIN_LATEX_PREVIEW, END_LATEX, LATEX_MACROS } from '../helpers/constant';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from 'react-spinners/ClipLoader';
import { Form } from 'react-bootstrap';
import { PaginatedItems } from './Pagination';
import { AudioPlayer } from './AudioPlayer';
import { ChevronUp } from 'lucide-react';

export function CodeEditor({
	audio,
	text,
	formulas,
	setSelectedTab,
	setSelectedFormula,
	jobId,
	memoText,
	setMemoText,
	hasPdf,
	memoTextRef,
	onChange,
	onCreateEditor,
}: {
	audio: string | false;
	text: TranscriptionType[];
	formulas: any;
	setSelectedTab: (selectedTab: 'Preview' | 'Formula' | 'Edit' | null | undefined) => void;
	setSelectedFormula:	(selectedFormula: [number, number] | null) => void;
	jobId: number;
	memoText: Record<
		number,
		{
			text: string;
			start_time: string;
		}
	>;
	setMemoText: (
		memoText: Record<
			number,
			{
				text: string;
				start_time: string;
			}
		>
	) => void;
	hasPdf: boolean;
	memoTextRef: React.MutableRefObject<Record<number, {
		text: string;
		start_time: string;
	}>>
	onChange: any;
	onCreateEditor: any
}) {
	const [allPdfs, setAllPdfs] = useState<Array<any>>([]);
	const [currentPdfSelected, setCurrentPdfSelected] = useState<number>(0);
	const [totalPagesSelectedPdf, setTotalPagesSelectedPdf] = useState<number>(0);
	const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
	const [currentPreview, setCurrentPreview] = useState<any>(null);
	const [value, setValue] = useState<any>('');
  const initialIndexes = useRef(new Map<string, { blockIndex: number, formulaIndex: number }>());
	const editorRef = useRef<ReactCodeMirrorRef>({});
	const [view, setView] = useState<EditorView | undefined>(undefined);
	const newWidgetsRef = useRef<Map<string, PlaceholderWidget>>(new Map());
	const [widgets, setWidgets] = useState<Map<string, PlaceholderWidget>>(new Map());
	const [cursorPos, setCursorPos] = useState(0);

	function joinTextRef(textArray) {
		let result = '';
		Object.values(textArray).map((item: any, i) => {
			const [minutes, seconds] = item.start_time.split(':');
			const formattedTimestamp = `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
			
			result += `\\( % ${formattedTimestamp} \\) \n${item.text}${item.text.endsWith('\n') ? '' : '\n'}`;
		})
		return result;
	}

	function latexAutocompletion(context:any) {
		let word = context.matchBefore(/\w*/)
		if (word.from == word.to && !context.explicit)
			return null
		return {
			from: word.from,
			options: LATEX_MACROS
		}
	}

	function handleFormulaClick(event: MouseEvent, blockIndex: any, formulaIndex: number, value: string) {
		if (formulas.length === 0) {
			return;
		}
		setSelectedTab('Formula');
		setSelectedFormula([blockIndex, formulaIndex]);

		// if (view) {
		// 	let pos = view.posAtCoords({ x: event.clientX, y: event.clientY });
		// 	if (typeof pos === 'number') {
		// 		setCursorPos(pos);
		// 		view.dispatch({
		// 			changes: { from: pos, to: pos },
		// 			selection: { anchor: pos },
		// 			scrollIntoView: true,
		// 		});
		// 	}
		// }
	}

	const handleFormulaDelete = (viewUpdate: ViewUpdate, latestDeletedWidget: any) => {
		const currentState = viewUpdate.view.state;
		const cursorPos = currentState.selection.main.head;
		const changes = {from: cursorPos, to: cursorPos, insert: latestDeletedWidget.value};
		const transaction = currentState.update({changes, scrollIntoView: true});
		viewUpdate.view.dispatch(transaction);
	}

	const updateWidgets = (viewUpdate: ViewUpdate) => {
    const updatedWidgets = new Map();
    let deletedWidget: PlaceholderWidget | null = null;

    for (const [id, widget] of Array.from(newWidgetsRef.current.entries())) {
			const widgetElement = document.getElementById(id);
			if (widgetElement) {
					updatedWidgets.set(id, widget);
			} else {
					deletedWidget = widget;
			}
    }

		// console.log('updatedWidgets:', updatedWidgets);
		// console.log('newWidgetsRef:', newWidgetsRef.current);
		
		if (viewUpdate.startState.doc.length > viewUpdate.state.doc.length) {
			if (deletedWidget !== null) {
				handleFormulaDelete(viewUpdate, deletedWidget);
				console.log('widget deleted:', deletedWidget);
			}
		}

    setWidgets(updatedWidgets);
		newWidgetsRef.current = updatedWidgets;
};

	class PlaceholderWidget extends WidgetType {
		id: string;
		
		constructor(public value: string, private blockIndex: number, private formulaIndex: number) {
			super();
			this.id = `formula-${blockIndex}-${formulaIndex}`;
		}
	
		toDOM() {
			const node = document.createElement('span');
			// set an id to the node
			node.setAttribute('id', `formula-${this.blockIndex}-${this.formulaIndex}`);
			node.textContent = this.value;
			node.ariaLabel = 'Formula';
			node.dataset.blockIndex = this.blockIndex.toString();
			node.dataset.formulaIndex = this.formulaIndex.toString();
			
			node.style.backgroundColor = '#3761b6';
			node.style.border = '1px solid #7982a9';
			node.style.borderRadius = '4px';
			node.style.padding = '0 4px';
			node.style.color = '#fff';
			node.style.cursor = 'pointer';

			node.addEventListener('click', (event: MouseEvent) => {
				handleFormulaClick(event, this.blockIndex, this.formulaIndex, this.value);
			});

			return node;
		}
	}

  const placeholderMatcher = new MatchDecorator({
    regexp: /<formula>(.*?)<\/formula>/g,
    decoration: (match, pos) => {
      if (pos) {
        const doc = (pos as any)?.state.doc;
        const content = doc.children ? doc.children.map((item: any) => item.text).flat() : doc.text;

        // Calculate the blockIndex and formulaIndex only if they're not already stored
        if (!initialIndexes.current.has(match[1])) {
          const blockIndex = content.findIndex((item: any) => item.includes(match[0])) + 1;
          const blockContent = content[blockIndex - 1];

          const placeholderRegexp = /<formula[^>]*>(.*?)<\/formula>/g;
          const placeholders = Array.from(blockContent.matchAll(placeholderRegexp), (m: RegExpMatchArray) => m[1]);
          const formulaIndex = placeholders.findIndex(formula => formula === match[1]);

          initialIndexes.current.set(match[1], { blockIndex, formulaIndex });
        }

        // Retrieve the initial block index and formula index from the ref
        const { blockIndex, formulaIndex } = initialIndexes.current.get(match[1])!;

				const widget = new PlaceholderWidget(match[1], (blockIndex / 2) - 1, formulaIndex);
				newWidgetsRef.current.set(widget.id, widget);
		
				return Decoration.replace({
					widget: widget,
				});
      } else {
        return null;
      }
    }
  });

	const placeholders = ViewPlugin.fromClass(class {
		placeholders: DecorationSet;
		constructor(view: EditorView) {
			this.placeholders = placeholderMatcher.createDeco(view)
		}
		update(update: ViewUpdate) {
			this.placeholders = placeholderMatcher.updateDeco(update, this.placeholders)
		}
		}, {
		decorations: instance => instance.placeholders,
		provide: plugin => EditorView.atomicRanges.of(view => {
			return view.plugin(plugin)?.placeholders || Decoration.none
		})
	})

	const getReadOnlyRanges = (targetState: any): Array<{ from: number | undefined, to: number | undefined }> => {
		const readOnlyRanges: Array<{ from: number | undefined, to: number | undefined }> = [
			{
				from: undefined, //same as: targetState.doc.line(0).from or 0
				to: targetState.doc.line(1).to
			},
		];
		const targetString = "\( % ";

		if (targetState.doc.children) {
			const content = targetState.doc.children.reduce((acc, item) => {
				return acc.concat(item.text.map((text: string) => ({ text })));
			}, []);
			content.forEach((text: any, textIndex: number) => {
				if (text.text.includes(targetString)) {
					const lineNumber = textIndex + 1;
					readOnlyRanges.push({
						from: targetState.doc.line(lineNumber).from,
						to: targetState.doc.line(lineNumber).to
					});
				}
			});
		} else {
			targetState.doc.text.forEach((text: string, textIndex: number) => {
				if (text.includes(targetString)) {
					const lineNumber = textIndex + 1;
					readOnlyRanges.push({
						from: targetState.doc.line(lineNumber).from,
						to: targetState.doc.line(lineNumber).to
					});
				}
			});
		}
	
		return readOnlyRanges;
	};

	const getCursorPosition = (data: any) => {
		const from = data.ranges[0].from;
		setCursorPos(from);
	}

	const getAllPdfsCallBack = useCallback(() => {
		getAllPdfs(jobId)
			.then(res => {
				setAllPdfs(res.data);
				if (res.data.lenght !== 0) {
					getPdfStats(jobId).then(res => {
						setTotalPagesSelectedPdf(res.data.end);
						setCurrentPreview(getPdfPage(jobId, 0, 0));
					});
				}
			})
			.catch(() => {
				setAllPdfs([]);
			});
	}, [jobId]);

	const changePdf = useCallback(
		e => {
			getPdfStats(jobId, e.target.value).then(res => {
				setCurrentPdfSelected(e.target.value);
				setTotalPagesSelectedPdf(res.data.end);
				setCurrentPreview(getPdfPage(jobId, e.target.value, 0));
			});
		},
		[jobId]
	);

	const changePdfPage = useCallback(
		index => {
			setCurrentPreview(getPdfPage(jobId, currentPdfSelected, index));
		},
		[currentPdfSelected, jobId]
	);

	useEffect(() => {
		if (hasPdf) {
			getAllPdfsCallBack();
		}
	}, [getAllPdfsCallBack, hasPdf]);

	useEffect(() => {
		document.addEventListener('click', event => {
			setMemoText(memoTextRef.current);
		});
	}, [memoTextRef, setMemoText]);

	useEffect(() => {
		const result = joinTextRef(memoText);
		// setValue(`${BEGIN_LATEX_PREVIEW}${result}${END_LATEX}`);
		setValue(`${result}`);
	}, []);

	useEffect(() => {
		// if (editorRef.current?.view) console.log('EditorView:', editorRef.current.view);
		// if (editorRef.current?.state) console.log('EditorState:', editorRef.current.state);
		// if (editorRef.current?.editor) console.log('HTMLDivElement:', editorRef.current.editor);
		if (editorRef.current?.view?.scrollDOM) editorRef.current.view.scrollDOM.scrollTop = 0;
	}, [editorRef.current]);

	// 	console.log(cursorPos);
	// }, [cursorPos]);

	return (
		<StyledCodeContainerEditing>
			<StyledHeader>{audio && <AudioPlayer audio={audio} />}</StyledHeader>
			<div className="content-body">
				<CodeMirror
					id="#latex-editor"
					ref={editorRef}
					value={value}
					theme={tokyoNightStorm}
					style={{ height: '100%' }}
					basicSetup={{
						foldGutter: false,
						dropCursor: false,
						allowMultipleSelections: false,
						indentOnInput: false,
						autocompletion: true,
						highlightActiveLine: false,
						highlightActiveLineGutter: false,
					}}
					extensions={[
						// langs.stex(),
						EditorView.lineWrapping,
						autocompletion({override: [latexAutocompletion]}),
						preventModifyTargetRanges(getReadOnlyRanges),
						placeholders,
					]}
					onChange={(value, viewUpdate) => {
						setValue(value);
						onChange(value);
						updateWidgets(viewUpdate);
					}}
					onStatistics={(data) => {
						getCursorPosition(data)
					}}
					onCreateEditor={(editor: EditorView) => {
						setView(editor);
						const result = joinTextRef(memoText);
						setValue(`${result}`);
						onCreateEditor(`${result}`);
					}}
				/>
			</div>
			{hasPdf && (
				<StyledButtonOpenSection isPreviewOpen={isPreviewOpen}>
					{' '}
					{isPreviewOpen && (
						<>
							<Form.Select aria-label="Default select example" onChange={e => changePdf(e)}>
								{allPdfs.map(pdf => {
									return (
										<option key={pdf.index} value={pdf.index}>
											{pdf.pdf_path.replace(/^.*[\\\/]/, '')}
										</option>
									);
								})}
							</Form.Select>
							<PaginatedItems
								itemsPerPage={1}
								totalItem={totalPagesSelectedPdf}
								changePdfPage={changePdfPage}
								currentPdfSelected={currentPdfSelected}
							/>
						</>
					)}
					{!isPreviewOpen && <div>Vedi PDF</div>}
					<div onClick={() => setIsPreviewOpen(!isPreviewOpen)} className="chevron" >
						<ChevronUp />
					</div>
				</StyledButtonOpenSection>
			)}
			{isPreviewOpen && (
				<PerfectScrollbar className="scrollbar">
					<StyledPdf>
						<UploadImage currentPreview={currentPreview} />
						<img id="previewPdf" src={currentPreview} alt="uploaded PDF preview" />
					</StyledPdf>
				</PerfectScrollbar>
			)}
		</StyledCodeContainerEditing>
	)
}

function UploadImage({currentPreview}:{currentPreview:any}){
	const [currentView,setCurrentView]=useState<JSX.Element>(<div className="loader"><Loader/></div>)
	const checkImage = (path:any) =>
    new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(path)
        img.onerror = () => reject()

        img.src = path
    })

	useEffect(()=>{
		setCurrentView(<div className="loader"><Loader/></div>)
		checkImage(currentPreview).then((img)=>{
			setCurrentView(<></>)

		}).catch(()=>{
			setCurrentView(<div className="loader"><Loader/></div>)
		})
	},[currentPreview])

	return currentView
}

const StyledButtonOpenSection = styled.div<{ isPreviewOpen: boolean }>`
	//font-weight: bold;
	height: 10vh;
	background: rgb(55, 97, 182, 0.1);
	color: #1c325e;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 2vh;

	padding: 0 2em 0 1em;
	.form-select {
		width: 30% !important;
	}
	svg {
		cursor: pointer;
		margin: 2px;
		transform: rotate(180deg);
		${props =>
			props.isPreviewOpen &&
			`transform: rotate(
        0);`}
	}
`;
const StyledPdf = styled.div`
	height: 40vh;
	width: 100%;
	position:relative;
	.loader{
		height:100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left:0;
		background: white;

	}
	img {
		width: inherit;
	}
	.loader + img{
		visibility: hidden;
	}
`;
const StyledCodeContainerEditing = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	.content-body {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
	}
	.scrollbar {
		height: 40vh !important;
	}
`;
const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content:space-evenly;
	align-items: center;
	height: 10vh;
	padding: 3px;
	background: rgb(255, 255, 251);
	border-bottom: 5px solid transparent;
	svg {
		width: 60px;
	}
	label {
		height: 30px;
		max-width: 80px;
		margin-left: 10px;
	}
`;
