import { useEffect, useState, useCallback, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export function PaginatedItems({
	itemsPerPage,
	totalItem,
	getAllJobsCallback,
	changePdfPage,
	currentPdfSelected
}: {
	itemsPerPage: any;
	totalItem: number;
	getAllJobsCallback?: (startIndex?: number, endIndex?: number, activePage?: number) => void;
	changePdfPage?: (index: number) => void;
	currentPdfSelected?: number;
}) {

	const memoItems = useMemo(() => {
		return new Array(totalItem + 1).fill(0);
	}, [totalItem])

	// We start with an empty list of items.
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
	const [selectedPage, setSelectedPage] = useState(0);

	useEffect(() => {
		// Fetch items from another resources.
		const endOffset = itemOffset + itemsPerPage;
		setPageCount(Math.ceil(memoItems.length / itemsPerPage));
		getAllJobsCallback && getAllJobsCallback(itemOffset, endOffset, selectedPage);
		changePdfPage && changePdfPage(selectedPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAllJobsCallback, itemOffset, memoItems, itemsPerPage]);

	// Invoke when user click to request another page.
	const handlePageClick = useCallback((event: any) => {
		if (typeof event === 'number') {
			setSelectedPage(0);
			setItemOffset(0);
		} else {
			const newOffset = (event.selected * itemsPerPage) % memoItems.length;
			setSelectedPage(event.selected);
			setItemOffset(newOffset);
		}
	}, [memoItems.length, itemsPerPage]);

	useEffect(() => {
		if (!currentPdfSelected) {
			return;
		}
		handlePageClick(0);
	}, [currentPdfSelected, handlePageClick]);
	return (
		<StyledPagination>
			{pageCount > 1 && <ReactPaginate
				breakLabel="..."
				previousLabel={prevButton()}
				nextLabel={nextButton()}
				onPageChange={handlePageClick}
				pageRangeDisplayed={3}
				pageCount={pageCount}
				forcePage={selectedPage}
			/>}
		</StyledPagination>
	);
}

function prevButton() {
	return (
		<StyledArrowButton>
			<ChevronLeft />
		</StyledArrowButton>
	);
}

function nextButton() {
	return (
		<StyledArrowButton>
			<ChevronRight />
		</StyledArrowButton>
	);
}

const StyledPagination = styled.div`
	ul {
		display: flex;
		align-items: center;
		gap: 0.3rem;
		list-style: none;
		padding: 0;
		margin:0;
		li {
			flex: 1;
			border-radius: 50%;
			a {
				font-size: 14px;
				height: 2em;
				width: 2em;
				display: flex;
				align-items: center;
				justify-content: center;
				color: black;
				cursor: pointer;
			}
			a:link,
			a:visited,
			a:hover,
			a,
			a:active {
				text-decoration: none;
				color: black;
			}
			&:hover {
				background: #ebebeb;
			}
			&.selected {
				color: white;
				background: #3761b6;
			}
			&.disabled {
				a:link,
				a:visited,
				a:hover,
				a {
					text-decoration: none;
					color: #b1b1b1;
					cursor: default;
				}
				&:hover {
					background: rgba(0, 0, 0, 0);
				}
			}
		}
	}
`;

const StyledArrowButton = styled.button`
	height: 2em;
	width: 2em;
	box-sizing: content-box;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	cursor: pointer;
	border-radius: 50%;
	border: none;
	background-color: transparent;
	&:hover {
		background-color: #ebebeb;
	}
`;
