
import axios from 'axios';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function getAllUsers(){
	return axios.get(`${SERVER_URL}/getAllUsers`, {
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

export function getUser(){
	return axios.get(`${SERVER_URL}/getUser`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

export function addUser(user_id:string){
	return axios.post(`${SERVER_URL}/addUser?user_id=${user_id}`, {
		headers: {
			'Content-Type': 'application/json'
		},

	})
}

