import axios from 'axios';
import { ExportType } from "../types/job.types";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function checkSpace(){
	return axios.get(`${SERVER_URL}/checkspace`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
export function getUploadedData(){
	return axios.get(`${SERVER_URL}/getUploadedData`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
// output:
// {
//   "video_path": ["voicemath_input/20_-Esercitazione-Analisi-I-2-dicembre-2021_taglio_2.mp4"],
//   "audio_path": None,
//   "pdf_paths": [
//      "voicemath_input/20) Esercitazione Analisi I 2 dicembre 2021.pdf"
//   ]
// }
export function getAllJobs(start?:number, end?:number){
	return axios.get(`${SERVER_URL}/getAllJobs`, {
		withCredentials: true,
	   headers: {
		   'Content-Type': 'application/json',
	   },
	   params: {
		start,
		end
	}
   })
}

// TODO: ripetere ogni X secondi solo se job BUSY
// output:
// [
//   {
//     "user_id": "user_1",
//     "job_id": 210,
//     "input_data": {
//       "video_path": "voicemath_input/user_1/video_2021-09-13_16-14-5-2.mp4",
//       "audio_path": null,
//       "pdf_paths": [
//         "voicemath_input/user_1/20) Esercitazione Analisi I 2 dicembre 2021.pdf"
//       ]
//     },
//     "output_data": null,
//     "status": "COMPLETED",
//     "export_path": "voicemath_output/user_1/210",
//     "error_message": null,
//     "start_time": "2022-06-01T11:31:58.681266",
//     "end_time": "2022-06-01T11:32:10.731818"
//   }
// ]


export function addJob(video_path:Array<string>,audio_path:Array<string>,pdf_paths:Array<string>|null){
	const body={
		video_paths: video_path,
		audio_paths: audio_path,
		pdf_paths: pdf_paths,
	   }
	return axios.post(`${SERVER_URL}/addJob`,body, {
		withCredentials: true,
	   headers: {
		   'Content-Type': 'application/json'
	   },
   })
}

export function getJob(jobId: string){
	return axios.get(`${SERVER_URL}/getJob`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
		},
		data: {},
		params: {
			job_id: jobId ? jobId : null
		}
	})
}

export async function deleteJob(jobId: string) {
  try {
		return await axios.get(`${SERVER_URL}/deletejob`, {
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			},
			params: {
				job_id: jobId
			}
		});
	} catch(error: any) {
		throw new Error(`Errore durante la cancellazione: ${error.message}`)
	}
}

export async function saveJob(jobId: string, audioPath: string | null, formulas: object[] | null, transcriptions: object[] | any | null, formula_suggestions: object | null){
	try {
		const body = {
			audio_path: audioPath,
			formulas,
			transcriptions,
			formula_suggestions
		}
		
		const response = await axios.post(`${SERVER_URL}/saveJob`, body, {
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json'
			},
			params: {
				job_id: jobId ? jobId : null
			}
		})

		return response.data;
	} catch(error: any) {
		throw new Error(`Errore durante il salvataggio: ${error.message}`)
	}
}

export function startNewJob(jobId: string) {
	return axios.get(`${SERVER_URL}/startJob`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json'
		},
		data: {},
		params: {
			job_id: jobId
		}
	});
};

export function getNewJob() {
	return axios.get(`${SERVER_URL}/getNewJob`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json'
		},
		data: {},
	});
};


export function exportLatex(file:ExportType) {
	return axios.post(`${SERVER_URL}/export`, file,{withCredentials: true}
	);
};


export function getAllPdfs(job_id:number){
	return axios.get(`${SERVER_URL}/allPdfs`, {
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json'
			},
		params: {
			job_id
		}
	})
}
export function getPdfStats(job_id:number,pdf_index?:number){
	return axios.get(`${SERVER_URL}/pdfStats`, {
		withCredentials: true,
	   headers: {
		   'Content-Type': 'application/json'
	   },
	   params: {
		job_id,
		pdf_index
	}
   })
}

export function getPdfPage(job_id:number,pdf_index?:number,page?:number){
	return `${SERVER_URL}/pdfPage?job_id=${job_id}&pdf_index=${pdf_index}&single_image=false&page_index=${page}`
}
// La chiamata /allPdfs?job_id=53 che restituisce la lista dei pdf per quel job, con un indice per ogni pdf
// [{"index": 0,
// "pdf_path": "voicemath_input/user_1/20) Esercitazione Analisi I 2 dicembre 2021.pdf"
// }]
// La chiamata /pdfStats?job_id=53&pdf_index=0 che ti restituisce, dato un job_id e l'indice di un pdf, quante pagine ha
// {"job_id": "53",
// "pdf_path": "voicemath_input/user_1/20) Esercitazione Analisi I 2 dicembre 2021.pdf",
// "pdf_index": 0,
// "start": 0,
// "end": 8
// }
// la chiamata /pdfPage?job_id=53&pdf_index=0&page_index=0 che, dato il job_id, l'indice del pdf e la pagina ti restituisce l'immagine
