import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { CodeEditor } from '../components/LatexEditor';
import { JobDataCompletedType } from '../types/job.types';
import { SERVER_URL, saveJob } from '../services/useServicesList';
import { Sidebar } from '../components/Sidebar';
import { makeCompilable, deleteCookie } from '../helpers/utils';
import { BEGIN_LATEX_PREVIEW, END_LATEX } from '../helpers/constant';
import { Tab, useTabs } from '../components/TabElements';
import toast, { Toaster } from 'react-hot-toast';
import MainDialog from '../components/MainDialog';
import { Button } from 'react-bootstrap';

export default function Edit({
	data,
	jobId,
	resetState,
	setIsLoggedIn,
	hasPdf,
	hasAudio
}: {
	data: JobDataCompletedType;
	jobId: number;
	resetState: () => void;
	setIsLoggedIn: (isLoggedIn: boolean) => void;
	hasPdf: boolean;
	hasAudio: boolean;
}) {
	const text = data.transcriptions;
	const audio = hasAudio&&`${SERVER_URL}/audio?job_id=${jobId}`;
	
	const [selectedTab, setSelectedTab] = useTabs(['Preview', 'Formula', 'Edit'], 'Preview');
	const [selectedFormula, setSelectedFormula] = useState<[number, number] | null>(null);
	const [loading, setIsLoading] = useState(false);
	const [editorValue, setEditorValue] = useState('');
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const formulas = useMemo(() => {
		if (data.formulas) {
			return data.formulas
				.map(x => {
					return x.formulas;
				})
				.flat();
		} else {
			return [];
		}
	}, [data.formulas]);

	const [tmpFormula, setTmpFormula] = useState<Record<number, string> | Array<string>>(
		formulas.reduce((obj, item) => Object.assign(obj, { [item.id]: item.latex }), {})
	);

	const handleTmpFormulaUpdate = (updatedTmpFormula: any) => {
    setTmpFormula(updatedTmpFormula);
  };

  const onEditFormula = useCallback((e, index) => {
    handleTmpFormulaUpdate({ ...tmpFormula, [index]: e });
  }, [tmpFormula]);

	const [memoText, setMemoText] = useState<Record<number, { text: string; start_time: string }>>(
		text.reduce(
			(obj, item, index) => Object.assign(obj, { [index]: { text: item.text, start_time: item.start_time } }),
			{}
		)
	);

	function renderLatexChunks(inputString:string) {
		const pattern = new RegExp(/(\\\[.*?\]|\\\(.*?\)|\\\\begin\{equation\}.*?\\\\end\{equation\})/g);
		const chunks = inputString.split(pattern);
		// console.log("🚀 ~ renderLatexChunks ~ chunks:", chunks);

		const result = chunks.map(chunk => {
			// if (pattern.test(chunk)) {
			// 	try {
			// 		if (chunk[1] === '[') {
			// 			return katex.renderToString(chunk.substring(2, chunk.length - 2), {
			// 				displayMode: true
			// 			})
			// 		} else {
			// 			return katex.renderToString(chunk.substring(2, chunk.length - 2), {
			// 				displayMode: false
			// 			})
			// 		}
			// 	} catch (e) {
			// 		throw e;  // other error
			// 	}
			// } else {
			// 	return chunk;
			// }
			return chunk
		});
	
		// Join the processed chunks back into a single string
		return result.join('');
	}

	const compileLatex = useCallback(() => {
		const compilableText = makeCompilable(editorValue);
		const formattedText = renderLatexChunks(compilableText);
		setEditorValue(formattedText);

		// console.log("📝 compiled!", formattedText);
	}, [editorValue]);

	const download = useCallback(() => {
		const filename = `voicemath_export_${jobId}.tex`;
		var element = document.createElement('a');

		const regex = new RegExp(/\\\( % .*? \\\)/g);
		const editorValueWithoutComment = editorValue.replace(regex, '');
		element.setAttribute(
			'href',
			'data:text/plain;charset=utf-8,' + encodeURIComponent(`${BEGIN_LATEX_PREVIEW}${editorValueWithoutComment}${END_LATEX}`)
		);
		element.setAttribute('download', filename);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}, [editorValue, jobId]);
	
	const logout = useCallback(() => {
		setIsLoggedIn(false)
		deleteCookie()
		resetState()
	}, [resetState, setIsLoggedIn]);

	const newJob = useCallback(() => {
		resetState();
	}, [resetState]);

	useEffect(()=>{
		const handleRejection = (promiseRejectionEvent: PromiseRejectionEvent) => {
			console.error("Unhandled promise rejection:", promiseRejectionEvent.reason);
		};
		window.addEventListener("unhandledrejection", handleRejection);
		return () => {
			window.removeEventListener("unhandledrejection", handleRejection);
		};
	}, []);	

	const memoTextRef = useRef<Record<number, { text: string; start_time: string }>>(
		text.reduce(
			(obj, item, index) => Object.assign(obj, { [index]: { text: item.text, start_time: item.start_time } }),
			{}
		)
	);
	
	const updateMemoTextRef=useCallback((newMemo)=>{
		memoTextRef.current = newMemo
	},[])

	const handleEditorValueChange = (newValue:any) => {		
		setEditorValue(newValue);
	}
	const handleEditorCreate = (initialValue:any) => {
		setEditorValue(initialValue);
	}

	const createMemoTextFromValue = (value:string) => {
		const regex = /^\d{2}:\d{2}/; // Matches nn:nn at the start of a string

		const splitted = value.split('\\( % ');
		const result = splitted.map((item:any, i:number) => {
			if (i === 0 || !regex.test(item)) return null;
			const [timestamp, text] = item.split(' \\) \n');
			return {
				start_time: timestamp,
				text: text.substring(0, text.length)
			}
		}).filter(item => item !== null);

		return result;
	}

	const save = useCallback(async () => {
		const saveToast = toast.loading('Salvataggio in corso...');
		try {
			const formatedMemoText = createMemoTextFromValue(editorValue);
			const formattedFormulas = data.formulas.map(x => {
				return { ...x, formulas: x.formulas.map(y => ({ ...y, latex: tmpFormula[y.id] })) };
			});
			await saveJob(jobId.toString(), data.audio_path || null, formattedFormulas, formatedMemoText, data.formula_suggestions);
			setTimeout(() => {
				toast.success('Salvataggio effettuato con successo', { id: saveToast });
			}, 1000);
		} catch (error) {
			console.error(error);
			setTimeout(() => {
				toast.error('Errore durante il salvataggio', { id: saveToast });
			}, 1000);
		}
	}, [jobId, data.audio_path, tmpFormula, editorValue]);

	return (
		<div id="outer-container">
			<Sidebar
				pageWrapId={'page-wrap'}
				outerContainerId={'outer-container'}
				setIsDialogOpen={setIsDialogOpen}
				download={download}
				save={save}
				logout={logout}
			/>
			<StyledBoxContainer id="page-wrap">
				<StyledHalfBoxContatiner>
					<CodeEditor
						audio={audio}
						hasPdf={hasPdf}
						text={text}
						formulas={formulas}
						setSelectedTab={setSelectedTab}
						setSelectedFormula={setSelectedFormula}
						jobId={jobId}
						memoText={memoText}
						setMemoText={setMemoText}
						memoTextRef={memoTextRef}
						onChange={handleEditorValueChange}
						onCreateEditor={handleEditorCreate}
					/>
				</StyledHalfBoxContatiner>
				<StyledHalfBoxContatiner>
					<Tab
						formulas={formulas}
						tmpFormula={tmpFormula}
						onEditFormula={onEditFormula}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						formulaSuggestions={data.formula_suggestions}
						selectedFormula={selectedFormula}
						compileLatex={compileLatex}
						editorValue={editorValue}
						memoText={memoText}
						setMemoText={setMemoText}
						updateMemoTextRef={updateMemoTextRef}
					/>
				</StyledHalfBoxContatiner>
			</StyledBoxContainer>
      <Toaster
				position="bottom-right"
				reverseOrder={false}
			/>
			<MainDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<MainDialog.Content
						title="Attenzione"
						description={`Sei sicuro/a di voler tornare alla schermata iniziale?\nTutte le modifiche non salvate andranno perse.`}
					>
						<div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
							<Button
								variant="flat"
								style={{ border: '1px solid rgb(55, 97, 182)' }}
								onClick={() => {
									setIsDialogOpen(false);
								}}
							>
								Annulla
							</Button>
							<Button
								variant="flat"
								style={{ backgroundColor: 'rgb(55, 97, 182)', color: 'white' }}
								type="submit"
								onClick={() => {
									newJob();
									setIsDialogOpen(false);
								}}
							>
								Torna alla schermata iniziale
							</Button>
						</div>
					</MainDialog.Content>
			</MainDialog>
		</div>
	);
}

const StyledBoxContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	background: rgb(255, 255, 251);
`;
const StyledHalfBoxContatiner = styled.div`
	width: 50%;
	height: 100vh;
	overflow-y: hidden;
`;
