import { END_LATEX } from "./constant";

export const beginLatex = () => {
	const today = new Date();
	const month = today.getMonth() + 1;
	const day = today.getDate();
	const year = today.getFullYear();
	const output = `${day}/${month}/${year}`;
	const dynamic = `voicemath ${output}`.toUpperCase();
	return `
  \\documentclass[a4paper,11pt,openright,oneside,titlepage]{report}\n
  \\usepackage[italian]{babel} %lingua italiana\n
  \\usepackage[T1]{fontenc}%\n
  \\usepackage[utf8]{inputenc}%\n
  \\usepackage{lmodern}%\n
  \\usepackage{amsmath}%\n
  \\usepackage{lastpage}%\n
  \\usepackage{spverbatim}%\n
  \\usepackage{amsmath}%\n
  \\usepackage{amsfonts,amsthm,amssymb}\n
  \\usepackage{graphicx}%\n
  \\usepackage{comment}\n
  \\usepackage{booktabs}\n

  \\begin{document}%\n

  \\begin{titlepage}\n
  \\thispagestyle{empty}\n
  \\centerline {\\Large{\\textsc{ UNIVERSITA' DEGLI STUDI DI TORINO}}}\n
  \\vskip 60 pt\n
  \\centerline {\\Large{\\textsc DIPARTIMENTO DI MATEMATICA GIUSEPPE PEANO}}\n
  \\vskip 60 pt\n
  \\centerline {{\\textsc SCUOLA DI SCIENZE DELLA NATURA}}\n
  \\vskip 120 pt\n
  \\vskip 120 pt\n
  \\centerline {{\\textsc DISPENSA CREATA DAL DIPARTIMENTO}}\n
  \\vskip 60 pt\n
  \\centerline {{\\textsc ${dynamic}}}\n
  \\end{titlepage}\n
  \\newtheorem{thm}{Theorem}
  \\chapter*{${dynamic}}\n`;
};
export const endLatex = () => {
	return END_LATEX;
};

export function makeCompilable(text:string){
  const replaceBeginFormulas= text.replaceAll("\\begin{equation}", "\\[")
  const replaceEndFormulas= replaceBeginFormulas.replaceAll("\\end{equation}", "\\]")
  return replaceEndFormulas
}

export function setCookie(cname:string, cvalue:string, exdays:number) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname:string) {
  var match = document.cookie.match(new RegExp('(^| )' + cname + '=([^;]+)'));
      if (match) {
        return match[2]
      }
      else{
           return ""
      }
}

export function checkCookie() {
  let username = getCookie("voicemath_session");
  if (username !== "") {
   return true
  }else{
    return false
  }
}

export function deleteCookie(){
  setCookie("voicemath_session", "",1)
}
