import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Latex from 'react-latex-next';

interface LatexPreviewProps {
  value: string;
}

const LatexPreview: React.FC<LatexPreviewProps> = ({ value }) => {
  const previewRef = useRef<HTMLIFrameElement | null>(null);

  // Function to compile LaTeX
  const compileLatex = (value: string) => {
    return value;
  };

  useEffect(() => {
    // Compile LaTeX when the component mounts and whenever the value prop changes
    compileLatex(value);
  }, [value]);

  return (
    <Wrapper>
      <h2>VoiceMath</h2>
      {/* <div>{value}</div> */}
      <Latex>{value}</Latex>
    </Wrapper>
  );
}

export default LatexPreview;

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 75%;
  margin: auto;
  padding-top: 50px;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  h2 {
    font-family: "Computer Modern Serif", serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    hyphens: manual;
    margin-bottom: 20px;
  }
  > span {
    word-break: normal !important;
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
    padding-bottom: 35px;
  }
  span {
    font-family: "Computer Modern Serif", serif;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: justify;
    hyphens: manual;
    span {
      text-align: center;
    }
  }
`;
