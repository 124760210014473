import styled from 'styled-components';
import Logo from '../assets/logo2.svg';
import LogoUnito from '../assets/unito.svg';
import LogoUnitoExtended from '../assets/unito-extended.svg';
import { useEffect, useState, useCallback } from 'react';
import { deleteCookie } from '../helpers/utils';
import { getUser } from '../services/useServiceAuthentication';

export function Header({
	setIsLoggedIn,
	isLoggedIn,
	isVisible
}: {
	setIsLoggedIn: (isLoggedIn: boolean) => void;
	isLoggedIn: boolean;
	isVisible: boolean | null;
}) 
{
	const handleLogin = useCallback(
		param => {
			if (param) {
				//TODO
			} else {
				deleteCookie()
			}
			setIsLoggedIn(param);
		},
		[setIsLoggedIn]
	);

	const [user, setUser] = useState<any>({});
	const getUserCallback = useCallback(() => {
		getUser()
			.then(res => {
				setUser(res.data);
			})
			.catch((e) => {
				setIsLoggedIn(false);
			});
	}, [setIsLoggedIn]);

	useEffect(() => {
		getUserCallback();
	}, [getUserCallback]);
	
	return (
		<>
			<StyledHeader isVisible={!!isVisible}>
				<StyledHeaderLeft>
					<img src={Logo} alt="VoiceMath" className="logo" />
					<a href="https://www.unito.it/" target="_blank" rel="noreferrer">
						<img src={LogoUnito} alt="Unito" className="logo-unito" />
					</a>
				</StyledHeaderLeft>
				<StyledHeaderRight>
					<a className='btn drive-folder' href={`https://drive.google.com/drive/folders/${user.drive_folder_id}`} target="_blank" rel="noreferrer">
						<span className='svg-folder'>
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M28.66667,22.93333c-6.33533,0 -11.46667,5.13133 -11.46667,11.46667v17.2v11.46667h108.93333h28.66667v-17.2c0,-6.33533 -5.13133,-11.46667 -11.46667,-11.46667h-73.39114l-3.53854,-5.9013c-2.06973,-3.45147 -5.80124,-5.56536 -9.83177,-5.56536zM14.34453,74.53333c-5.78493,0 -9.92754,5.60379 -8.23047,11.13073l17.02083,55.30651c1.48493,4.81027 5.92889,8.09609 10.96276,8.09609h51.90234h51.90234c5.03387,0 9.47783,-3.28582 10.96276,-8.09609l17.02083,-55.30651c1.69707,-5.52693 -2.44554,-11.13073 -8.23047,-11.13073h-71.65547z"></path></g></g></svg>
						</span>
						Cartella Google Drive
					</a>
					<a href="https://www.unito.it/" target="_blank" rel="noreferrer">
						<img src={LogoUnitoExtended} alt="Unito" className="logo-unito-extended" />
					</a>
					{/* {isLoggedIn ? (
						<StyledButton onClick={() => handleLogin(false)}>Logout</StyledButton>
					) : (
						<StyledButton onClick={() => handleLogin(true)}>Login</StyledButton>
					)}
					{isLoggedIn && <StyledButton onClick={() => handleLogin(false)}>Logout</StyledButton>}*/}
				</StyledHeaderRight>
			</StyledHeader>
		</>
	);
}

const StyledHeaderLeft = styled.div`
	padding:1.75rem;
`;
const StyledHeaderRight = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	// flex: 0.1;
	margin-right: 2rem;
	height: 100%;
	div[role='menu'] {
		visibility: hidden;
	}
	div[role='menu'].visible {
		visibility: visible;
	}
	.svg-folder {
		margin-right: .5rem;
	}
	.drive-folder {
		background-color: #3761b6;
		color: white;
	}
`;

const StyledHeader = styled.header<{ isVisible: boolean }>`
	display: ${props => (props.isVisible ? 'flex' : 'none')};
	align-items: center;
	justify-content: space-between;
	font-family: 'Titillium Web', sans-serif;
	font-size: 18pt;
	box-shadow: inset 0 0.5rem 0 #3761b6;
	.logo {
		height: 3rem;
	}
	.logo-unito {
		height: 1.5rem;
		margin-left: 2rem;
	}
	.logo-unito-extended {
		height: 3rem;
		margin-left: 2rem;
	}
`;
const StyledButton = styled.button`
	border: none;
	padding: 2rem 1rem 4rem;
	outline: none;
	background: none;
	height: 2.5rem;
	border-radius: 0 0 10px 10px;
	color: black;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 14pt;
	box-shadow: inset 0 0px 0 0px #3761b6;
	transition: box-shadow 0.5s ease-in-out;
	&:hover {
		color: white;
		cursor: pointer;
		text-decoration: underline;
		text-underline-offset: 5px;
		box-shadow: inset 0 10rem 0 0.1px #3761b6;
	}
`;
